import { ChangeDetectorRef, Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { merge, Subject } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { allAdditionnalThematics, allMainThematics, contentDurations, contentFormats, pillars } from "../models/contents.entity";
import { ContentsRepository } from "../repositories/contents.repository";

@Component({
    selector: "app-contents-list",
    templateUrl: "./contents-list.component.html",
    styleUrls: ["./contents-list.component.scss"],
})

export class ContentsListComponent {
    items: any;

    contentsForm!: FormGroup;

    contentsFilterForm!: FormGroup;

    searchForm: FormControl<string | null> = new FormControl<string>("");

    selectedItems: Set<string> = new Set();

    constructor(private router: Router, private route: ActivatedRoute, private readonly contentsRepository: ContentsRepository, private fb: FormBuilder, private cdr: ChangeDetectorRef) { }

    selectedTab: number = 1;

    objectives: string[] = ['inspire', 'prepare'];

    updateFormatsSelected$ = new Subject<void>();

    formatCheckboxes: Checkbox[] = [];

    isDropdownVisible = false;

    fetchTabContent(tabIndex: number) {
      this.selectedTab = tabIndex;
      if(tabIndex === 1){
        this.objectives = ['inspire'];
      }

      if(tabIndex === 2){
        this.objectives = ['act'];
      }

        if(tabIndex === 3){
            this.objectives = ['transform', 'prepare'];
        }

        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this.contentsRepository.findAll({ properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe((contents: any) => {
            this.items = contents.items;
        });
    }

    selectTab(tabIndex: number) {
      this.selectedTab = tabIndex;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { tab: tabIndex },
        queryParamsHandling: 'merge'
      });
      if(tabIndex === 1){
        this.objectives = ['inspire'];
      }

      if(tabIndex === 2){
        this.objectives = ['act'];
      }

        if(tabIndex === 3){
            this.objectives = ['transform', 'prepare'];
        }

        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this.contentsRepository.findAll({ properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe((contents: any) => {
            this.items = contents.items;
        });
    }

    

    ngOnInit(): void {

      this.route?.queryParams?.subscribe(params => {
        const tab = +params.tab || 1; 
        this.fetchTabContent(tab);
      });
      
      this.contentsForm = this.fb.group({
        allMainThematics: this.fb.array(this.createItems(allMainThematics)),
        allAdditionnalThematics: this.fb.array(this.createItems(allAdditionnalThematics)),
        formats: this.fb.array(this.createItems(contentFormats)),
        durations: this.fb.array(this.createItems(contentDurations)),
        pillars: this.fb.array(this.createItems(pillars)),
      });
        
        this.contentsFilterForm = this.fb.group({
          durationMinimum: new FormControl('', { nonNullable: true }),
          durationMaximum: new FormControl('', { nonNullable: true }),
        });

        this.subscribeToChanges();
        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this.contentsRepository.findAll({ properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe((contents: any) => {
            this.items = contents.items;
        });
    }

    private subscribeToChanges() {
        merge(
          this.contentsForm.valueChanges,
          this.contentsFilterForm.valueChanges
        ).subscribe(() => {
          const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
          const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
          const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
          const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
          this.contentsRepository.findAll({ properties: { objectives: this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe((contents: any) => {
            this.items = contents.items;
          });
        });
    }

    toggleSelection(item: any): void {
      const pillar: any = this.pillars.controls.find((control: any) => control.value.key === item.key);
      if (pillar) {
        pillar.patchValue({ value: !pillar.value.value });
      } 
    }

    createItems(thematicsPart: any[]): FormGroup[] {
      return thematicsPart.map(item => this.fb.group({
        name: [item.label],
        value: [item.selected],
        key: [item.key],
        icon: [item.icon]
      }));
    }

    openMoreThematic(event: Event){
      event.preventDefault();
      this.isDropdownVisible = !this.isDropdownVisible;
    }

    handleEnterPressed(value: string) {
      // window._mtm.push({ 'event' : 'recherche-ressource', 'motcle' : `${value.trim()}` });
      const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
      const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
      const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
      const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
      this.contentsRepository.findAll({ search: value.trim(),properties: { objectives: this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe((contents: any) => {
        this.items = contents.items;
      });
    }

      get pillars() {
        return this.contentsForm.get('pillars') as FormArray;
      }

    
      get formats() {
        return this.contentsForm.get('formats') as FormArray;
      }
    
      get durations() {
        return this.contentsForm.get('durations') as FormArray;
      }

      get thematics(){
        return this.contentsForm.get('allMainThematics') as FormArray;
      }

      get additionnalThematics(){
        return this.contentsForm.get('allAdditionnalThematics') as FormArray;
      }
}