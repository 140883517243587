import { Component, Input, OnChanges } from '@angular/core';

import { DynamicType } from "../../../thematics/models/dynamic.model";
import { TrainMembership } from "../../../trains/models/membership.entity";
import { DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-dynamic-type-distribution',
  templateUrl: './dynamic-type-distribution.component.html'
})
export class DynamicTypeDistributionComponent extends AbstractChartComponent<'doughnut'> implements OnChanges {

  @Input() trainMemberships!: TrainMembership[];

  type: "doughnut" = 'doughnut';

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        },
        position: 'bottom' as const
      }
    }
  };

  ngOnChanges() {
    super.onChanges();
  }

  updateData() {

    const trains = this.trainMemberships.map(membership => membership.train);
    this.chartData.labels = [ 'Coaching en groupe', 'Parole en groupe' ];
    this.chartData.datasets = [
      {
        label: 'Parcours',
        data: [
          trains.filter(train => train.dynamic.type === DynamicType.COACHING).length,
          trains.filter(train => train.dynamic.type === DynamicType.SPEECH).length,
        ],
        backgroundColor: this.getDefaultColors().slice(0, 2)
      }
    ];
  }

}
