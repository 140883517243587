import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { DIALOG_DATA } from "src/app/shared/components/dialog/dialog.token";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { IconType } from "../../shared/components/icon/icon.component";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Feedback } from "../models/feedbacks.entity";
import { FeedbacksRepository } from "../repositories/feedbacks.repository";

export type FeedbackForm = {
    rating: number | undefined;
    comment: string | undefined;
  }

@Component({
    selector: 'app-add-feedback',
    templateUrl: './add-feedback.component.html',
    styleUrls: ['./add-feedback.component.scss']
  })

export class AddFeedbackComponent extends DialogComponent<Feedback, {
    rating: number
  }>()   implements OnInit, OnDestroy {

    feedbackForm: EntityFormGroup<FeedbackForm>;

    loading = false;

    rating: number | undefined;

    emojiTypes: IconType [] = ['sad-emoji', 'woozy-emoji', 'neutral-emoji', 'happy-emoji', 'laughy-emoji'];

    emojiSelected: FormControl = new FormControl<number | undefined>(undefined);

    constructor( @Inject(DIALOG_DATA) public override readonly data: { rating: number }, private readonly ref: DialogRef<string>, private readonly formBuilder: FormBuilder, private readonly feedbacksRepository: FeedbacksRepository) {
        super(ref);
        this.rating = data.rating;
        this.feedbackForm = this.formBuilder.group({
            rating: new FormControl<number | undefined>(data.rating, { validators: Validators.required, nonNullable: true }),
            comment: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        });
        this.emojiSelected.setValue(data.rating);
    }

    static override isPopIn = true;

    ngOnInit() {
        super.onInit();
    }
    
    ngOnDestroy() {
        super.onDestroy();
    }

    sendFeedback() {
        if(this.feedbackForm.value.rating){
            // window._mtm.push({
            //     'event': 'clic-satisfaction-popup',
            //     'nom-bouton': 'Confirmation'
            //   });
            this.loading = true;
            this.feedbacksRepository.createFeedback({
                rating: this.feedbackForm.value.rating,
                comment: this.feedbackForm.value.comment
            }).subscribe((feedback) => {
                this.loading = false;
                this.close(feedback);
            });
        }
    }

    onEmojiClick(rating: number) {
        this.feedbackForm?.get('rating')?.setValue(rating); 
    }

    handleCancel(){
        // window._mtm.push({
        //     'event': 'clic-satisfaction-popup',
        //     'nom-bouton': 'Plus tard'
        //   });
        this.close();
    }
}