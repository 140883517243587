<h1>Appel d'orientation</h1>
<div class="margin-top">
    <p>
        Vous allez être appelé.e par un coach We talk qui tentera de vous orienter et de répondre à vos questionnements
        ou inquiétudes.
    </p>
    <p>
        L'appel durera environ 10 à 15 minutes.
    </p>
    <p class="margin-top-xl margin-bottom">
        Si vous le souhaitez, vous pouvez laisser un message expliquant votre situation ou votre problématique ci-dessous.
    </p>
    <form [formGroup]="orientationForm" (submit)="validate(true)">
        <div>
            <textarea class="margin-bottom" formControlName="orientationMessage" placeholder=""></textarea>
        </div>
        <div>
            <p class="margin-bottom">Votre numéro de téléphone</p>
            <app-input
                [correct]="phoneWork"
                formControlName="phoneNumber" type="phone"
                placeholder="0612345678"
                [phoneCode]="phoneRegionCode"
                (selectPhoneCode)="selectPhoneRegionCode($event)"></app-input>
        </div>
        <div>
            <span>Appel de préférence :</span>
            <div class="button-container">
                <div class="button-like red-button" [class.selected]="selectedOption === 'urgence'" (click)="selectOption('urgence')">Dès que possible</div>
                <div class="button-like not-red-button" [class.selected]="selectedOption === 'matin'" (click)="selectOption('matin')">Le matin</div>
                <div class="button-like not-red-button" [class.selected]="selectedOption === 'midi'" (click)="selectOption('midi')">Le midi</div>
                <div class="button-like not-red-button" [class.selected]="selectedOption === 'apres-midi'" (click)="selectOption('apres-midi')">L'après-midi</div>
            </div>
        </div>
        <div class="orientation-button-container">
            <app-button [disabled]="!orientationForm.valid || loading" icon="arrow-right" type="submit">Être appelé.e</app-button>
        </div>
        <div class="margin-top">
            <div>
                <i>We talk n'est pas un service d'urgence. En cas de besoin, contactez le 15.</i>
            </div>
            <div class="last-warning-container">
                <i>Si vous êtes en détresse et/ou avez des pensées suicidaires, si vous voulez aider une personne en souffrance, vous pouvez contacter le numéro national de prévention du suicide, le 3114.</i>
            </div>
        </div>
    </form>
</div>