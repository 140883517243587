import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { DemandsService } from "../authentication/services/demands.service";
import { CongratsComponent, CongratsType } from "../shared/components/congrats/congrats.component";
import { DialogService } from "../shared/components/dialog/dialog.service";
import { ProfileStore } from '../shared/services/profile.storage.service';
import { EntityFormGroup } from "../shared/types/entity-form-group";
import { ProfileService } from '../users/services/profile.service';

export enum QuestionType {
  THEMATICS = 'thematics',
  TRAINS = 'trains',
  SHERPA = 'sherpa',
  GENERAL = 'general',
  TECHNICAL = 'technical',
  OTHER = 'other',
  UNITS = 'units'

}

export type QuestionForm = {
  subject: string;
  content: string;
}

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: [ './questions.component.scss' ]
})
export class QuestionsComponent implements OnDestroy {

  questionForm: EntityFormGroup<QuestionForm>;


  private subjectRecord: Record<QuestionType, string> = {
    [QuestionType.THEMATICS]: 'Sur les thématiques',
    [QuestionType.SHERPA]: 'Sur les sherpas',
    [QuestionType.TRAINS]: 'Sur les parcours',
    [QuestionType.GENERAL]: 'Sur WE TALK',
    [QuestionType.TECHNICAL]: 'Sur un problème technique',
    [QuestionType.OTHER]: 'Sur un autre sujet',
    [QuestionType.UNITS]: 'Sur les crédits',
  };

  profile: any;


  private destroy$ = new Subject<void>();

  // private readonly tracker = inject(MatomoTracker);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly demandsService: DemandsService,
    private readonly dialog: DialogService,
    private readonly router: Router,
    private profileStore: ProfileStore,
    private readonly profileService: ProfileService
  ) {
    // this.tracker.trackPageView('Contact');
    this.questionForm = this.formBuilder.group({
      subject: new FormControl('', { validators: Validators.required, nonNullable: true }),
      content: new FormControl('', { validators: Validators.required, nonNullable: true })
    });
  }

  get subjects(): string[] {
    return Object.values(this.subjectRecord);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  sendEmail() {

    this.demandsService.sendQuestion({
      subject: Object.values(QuestionType).find(type => this.subjectRecord[type] === this.questionForm.controls.subject.value) ?? 'other',
      content: this.questionForm.controls.content.value
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialog.open(CongratsComponent, {
          title: `Merci pour votre sollicitation !`,
          message: 'Nous allons vous répondre dans les plus brefs délais sur votre adresse mail.',
          type: CongratsType.POST_NEW_SHERPA
        }).subscribe(() => {
          this.router.navigate([ 'home' ]);
        });
      });

  }
}
