import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
// import { MatomoTracker } from 'ngx-matomo-client';
import { Subject, takeUntil } from "rxjs";

import { OrganizationRepository } from "../../../../organizations/repositories/organization.repository";
import { CreateTrainService } from "../../../../thematics/create-train.service";
import { Dynamic, DynamicType } from "../../../../thematics/models/dynamic.model";
import { Thematic } from "../../../../thematics/models/thematic.model";
import { TrainRepository } from "../../../../trains/repositories/train.repository";
import { SnackbarService } from "../../snackbar/snackbar.service";

@Component({
  selector: 'app-dynamics',
  templateUrl: './dynamics.component.html',
  styleUrls: [ './dynamics.component.scss' ]
})
export class DynamicsComponent implements OnDestroy {
  @Input() thematic?: Thematic;

  @Input() dynamics?: Dynamic[];

  @Input() canValidate = false;

  @Input() goToAvailableTrains = false;

  destroy$ = new Subject<void>();

  // private readonly tracker = inject(MatomoTracker);

  constructor(
    private readonly trainRepository: TrainRepository,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
    private readonly createTrainService: CreateTrainService,
    private readonly organizationRepository: OrganizationRepository) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getDynamics(): Dynamic[] {
    return this.dynamics ?? this.thematic?.dynamics?.filter(dynamic => dynamic.isEnabled) ?? [];
  }

  chooseDynamic(type: DynamicType): void {
    const dynamic = this.getDynamics()?.find(d => d.type === type);
    const { thematic } = this;
    if (dynamic && thematic) {
      // FIXME: redundance ?
      // window._mtm.push({ 'event': 'creation-parcours', 'nom-parcours': thematic.title });
      // window._mtm.push({ 'event': 'nouvel-horaire-parcours', 'nom-parcours': thematic.title });
      // this.tracker.trackEvent(TrackerCategory.THEMATIC_PAGE_TALKER, TrackerAction.CLICK, `${dynamic.type}GroupSignup`);
      this.organizationRepository.canConsumeUnit()
        .pipe(takeUntil(this.destroy$))
        .subscribe(can => {
          if (can.unitAvailable && can.quota && this.thematic) {
            this.trainRepository.getAvailableTrains().pipe(takeUntil(this.destroy$))
              .subscribe(trains => {
                this.createTrainService.selectDynamic(dynamic, thematic, this.goToAvailableTrains && !!trains.filter(t => t.dynamic.id === dynamic?.id).length);
              });
          } else {
            this.snackbarService.pushMessage(!can.unitAvailable ? "Votre entreprise n'a plus d'unité disponible" : "Vous avez consommé le quota d'unités qui vous a été accordé par votre entreprise.", "error");
          }
        });
    }
  }

  parseDynamic = (dynamic: Dynamic) => ({
    title: dynamic.dynamicType,
    bulletPoints: dynamic.description.split('\n')
  });

  goToDynamicDistinction() {
    // this.tracker.trackEvent(TrackerCategory.THEMATIC_PAGE_TALKER, TrackerAction.CLICK, 'learnmoreLink');
    this.router.navigate(['dynamics-distinction']);
  }
}
