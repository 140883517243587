<div class="input-wrapper">
  <p *ngIf="title">{{title}}</p>
  <div class="input-container"
       [class.phone]="type === 'phone'"
       [class.correct]="correct"
       [class.error]="error">
    <div class="text-primary center-vertically-by-margin">
      <app-icon *ngIf="leftIcon && withLeftIcon && type !== 'phone'" [type]="leftIcon"></app-icon>
    </div>
    <ng-container *ngIf="type === 'phone'">
      <div class="relative input-phone-code pointer" [dropdownTriggerFor]="dropdownPhone">
        <div *ngIf="phoneCode" class="center-vertically-by-relative-parent row">
          <div>
            <span [class]="getPhoneIconFromRegion(phoneCode.region)"></span>
          </div>
          <div class="phone-code"> +{{phoneCode.code}}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="dropdownData === undefined && !addOptionEnable && ((editable && isEditing) || !editable)">
      <input spellcheck="false" class="input-field"
             [class.text-right]="type==='number'"
             [class.number]="type==='number'"
             [class.dropdown-input]="type==='dropdown'"
             [formControl]="inputField"
             [class.pointer]="type=== 'time' || type=== 'dropdown' || type === 'date'"
             [class.no-value]="noValue"
             [type]="
           type === 'password' ? passwordShown ? '' : type :
           type === 'phone' ? 'number' : type"
             [name]="type"
             [id]="id ?? type"
             [readonly]="readonly"
             [placeholder]="placeholder"ad
             [pattern]="type === 'number' ? '[0-9]*': ''"
             [min]="type === 'number' && !this.negativeInput ? this.min : ''"
      >
      <div class="filter-counter" *ngIf="isSearch && gotSubMenu && filterOptionCount">{{filterOptionCount}}</div>
      <app-icon class="icon pointer" *ngIf="isPassword && passwordVisibleEnabled" (click)="showPassword()"
               type="eye-closed" />
      <app-icon class="icon pointer" *ngIf="isSearch && gotSubMenu" (click)="editFilter()"
        [ngClass]="{'has-filters': hasFilters}" type="filter"></app-icon>
      <div class="center-children">

        <app-icon  *ngIf="isCheckmarks" type="chevron-down"></app-icon>
      </div>
    </ng-container>
    <ng-container *ngIf="editable && !isEditing">
      <p (click)="focus()" [formControl]="inputField"></p>
    </ng-container>


    <ng-container *ngIf="dropdownData !== undefined || addOptionEnable">
      <input spellcheck="false" class="input-field pointer"
             [formControl]="inputField"
             [name]="type"
             [id]="id ?? type"
             readonly
             [placeholder]="placeholder"
             autocomplete="off"
             (click)="showSimpleDropdown()"
      >
      <div class="center-children">
        <app-icon type="chevron-down" (click)="showSimpleDropdown()"></app-icon>
      </div>
    </ng-container>

  </div>
  <div #subMenuContainer *ngIf="isSearch" [dropdownTriggerFor]="filterDropdown"></div>
  <div #simpleDropdownContainer *ngIf="dropdownData !== undefined || addOptionEnable"
       [dropdownTriggerFor]="dropdown"></div>
  <p *ngIf="errorMessage !== 'disabled' || marginBottom || (type === 'number' && inputField.getRawValue() < 0  &&  !this.negativeInput)"
     class="error">{{ type === 'number' && inputField.getRawValue() < 0 && !this.negativeInput ? 'Nombre positif exigé' : error ? errorMessage : '' }}</p>

</div>

<app-dropdown #dropdownPhone [closeOnClick]="false" class="dropdown-phones">
  <div *ngFor="let phoneCodeItem of phoneCodes" (mousedown)="onSelectPhoneCode(phoneCodeItem)"
       (mouseup)="dropdownPhone.close()" class="dropdown-item">
    <span [class]="getPhoneIconFromRegion(phoneCodeItem.region)"></span> +<span>{{phoneCodeItem.code}}</span>
  </div>
</app-dropdown>
<app-dropdown #dropdown>
  <div *ngIf="addOptionEnable" class="dropdown-item" (click)="onAddOption()">

    <app-icon size="18" type="add" />
    <span>Ajouter</span>
  </div>
  <div *ngIf="!!inputField.value" class="dropdown-item" (click)="onRemoveSelection()">
    <app-icon size="18" type="trash"></app-icon>
    <span>Supprimer</span>
  </div>
  <div *ngFor="let option of dropdownData" (click)="selectOption(option)" class="dropdown-item">
    {{ option }}
  </div>
  <div *ngIf="dropdownData?.length === 0 && !addOptionEnable" class="dropdown-item">
    Aucun résultat
  </div>
</app-dropdown>
<app-dropdown [closeOnClick]="false" [isFilter]="true" #filterDropdown class="dropdown-wrapper">
  <div #ref>
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="!ref.hasChildNodes()">
    <div class="default-filter">
      Aucun filtre disponible
    </div>
  </ng-container>
</app-dropdown>
