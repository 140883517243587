<div class="padding-wrapper column">
  <div class="wrapper" >
    <h1 class="margin-bottom">Mon espace</h1>
    <div class="tabs-container">
      <div class="tabs">
          <button class="tab-button row center-children-vertically gap" (click)="selectTab(1)" [class.active]="selectedTab === 1"><app-icon type="calendar-variant" size="22"></app-icon><span>Mes parcours</span></button>
          <button class="tab-button row center-children-vertically gap" (click)="selectTab(2)" [class.active]="selectedTab === 2"><app-icon type="heart-square" size="22"></app-icon><span>Mon état émotionnel</span></button>
          <button class="tab-button row center-children-vertically gap" (click)="selectTab(3)" [class.active]="selectedTab === 3"><app-icon type="haltere" size="22"></app-icon><span>Ma pratique</span></button>
      </div>
    </div>
    <ng-container *ngIf="!loading; else loadingSpinner">
      <div *ngIf="selectedTab === 1">
        <div *ngIf="currentTrains.length + overTrains.length" class="margin-bottom">
          <div class="resume row">
            <div class="tile" *ngIf="currentTrains.length">
              <div class="text-xl text-primary cooper">{{currentTrains.length}}</div>
              <div class="cooper text-black">parcours en cours</div>
            </div>
            <div class="tile" *ngIf="overTrains.length">
              <div class="text-xl text-primary cooper">{{overTrains.length}}</div>
              <div class="cooper text-black">parcours réalisé{{overTrains.length > 1 ? 's' : ''}}</div>
            </div>
            <div class="tile" *ngIf="sessionsDone">
              <div class="text-xl text-primary cooper">{{sessionsDone}}</div>
              <div class="cooper text-black">séance{{sessionsDone > 1 ? 's' : ''}}
                réalisée{{sessionsDone > 1 ? 's' : ''}}</div>
            </div>
            <div class="tile" *ngIf="hoursDone">
              <div class="text-xl text-primary cooper">{{hoursDone}}h</div>
              <div class="cooper text-black">de temps pour moi</div>
            </div>
          </div>
        </div>
        <h2 *ngIf="currentTrains.length" class="margin-bottom margin-top">Sessions à venir</h2>
        <div *ngIf="currentTrains.length" class="column gap">
          <app-train-card class="margin-bottom" *ngFor="let train of currentTrains" [train]="train"
                          (goToMeeting)="goToMeeting($event)" (goToDetails)="goToTrain(train)"></app-train-card>
        </div>
      
        <div *ngIf="overTrains.length" class="column gap">
          <h2 class="margin-bottom margin-top">Parcours finalisé{{overTrains.length > 1 ? 's' : ''}}</h2>
          <app-train-card class="margin-bottom" *ngFor="let train of overTrains" [train]="train"
                          (goToDetails)="goToTrain(train)"></app-train-card>
        </div>
      
        <h2 class="margin-bottom margin-top">Thématiques favorites</h2>
        <div *ngIf="favoriteThematics.length; else noFavorites">
          <div class="resume row margin-bottom">
            <div class="tile">
              <div class="text-xl text-primary cooper">{{favoriteThematics.length}}</div>
              <div class="cooper text-black">thématique{{ favoriteThematics.length > 1 ? 's' : '' }}
                favorite{{ favoriteThematics.length > 1 ? 's' : '' }}</div>
            </div>
            <div class="tile" *ngIf="favoriteCategories.length">
              <div class="text-xl text-primary cooper">{{favoriteCategories.length}}</div>
              <div class="cooper text-black">famille{{favoriteCategories.length > 1 ? 's' : ''}} de thématiques</div>
            </div>
            <div class="tile-bubble">
              <div class="bubble" [style.border-color]="mediumCategoryColors[category.slug]"
                   *ngFor="let category of favoriteCategories">{{capitalize(category.name)}}</div>
            </div>
      
          </div>
      
          <div class="wrap start">
            <div
              class="thematic" *ngFor="let thematic of favoriteThematics">
              <app-thematic-card [thematic]="thematic"
                                 (click)="clickOnThematic(thematic.slug)"></app-thematic-card>
            </div>
          </div>
        </div>
        <ng-template #noFavorites>
          <app-notification 
            class="margin-bottom" 
            buttonMessage="Découvrez les thématiques"
            (onButtonClick)="goToThematics()" 
            color="#2CBBB6" 
            icon="favorite-outline"
            title="Renseignez vos thématiques favorites">
            Identifiez les thèmes qui vous correspondent.
            Recevez des notifications quand un parcours est lancé.
          </app-notification>
        </ng-template>
      </div>

      <div *ngIf="selectedTab === 2" class="margin-top margin-bottom">
        <ng-container *ngIf="emotionalResponseData">
          <h2>Détail de votre dernier bilan émotionnel</h2>
          <app-emotional-response [emotionalResponse]="emotionalResponseData"></app-emotional-response>
          <div class="graph-section column margin-top">
            <p class="margin-bottom text-bold text-center">Forces et faiblesses</p>
            <div class="chart-container">
              <app-strengths-weakness-chart [isSmall]="true" [data$]="radarChartDataSubject$"></app-strengths-weakness-chart>
            </div>
          </div>
        </ng-container>
        <app-typeform-responses-panel />
      </div>

      <div *ngIf="selectedTab === 3">
        <div class="column center-children-vertically no-contents-container">
          <div class="no-contents">
              <h2>BIENTÔT SUR WE TALK...</h2>
              <p class="margin-top-xl">
                Ici, vous pourrez
                <strong class="text-primary">organiser votre pratique du bien-être</strong>,
                à votre manière ! 
              </p>
              <p>Auto-coaching en 9, 12 ou 21 jours, jeu de carte et autres outils sont en préparation.</p>
              <p>Envoyez nous vos suggestions via le formulaire “Contact” !</p> 
          </div>
          <img class="responsive-img" src="assets/images/meditation.png" alt="construction">
        </div>
      </div>
    </ng-container>

    <ng-template #loadingSpinner>
      <div class="spinner">
        <app-spinner></app-spinner>
      </div>
    </ng-template>
  </div>
</div>
