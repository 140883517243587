import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconType } from "../icon/icon.component";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  @Input() color?: string;

  @Input() buttonMessage?: string;

  @Input() checkMessage?: string;

  @Input() outline = false;

  @Input() icon?: IconType;

  @Input() title?: string;

  @Input() isPrimaryCta = false;

  @Input() buttonWidth: 'none' | 'full' | 'auto' | 'fixed' = 'none';

  @Input() fixedWidth = '500px';

  @Output() onButtonClick = new EventEmitter<void>();

  onPanelButtonClick() {
    this.onButtonClick.emit();
  }
}
