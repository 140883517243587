import { Component, forwardRef, OnDestroy, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
// import { MatomoTracker } from "ngx-matomo-client";
import {
  combineLatestWith,
  filter,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { ArchiveOrganizationDialog } from "src/app/shared/components/archive-organization-dialog/archive-organization-dialog";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Thematic } from "../../thematics/models/thematic.model";
import { ThematicRepository } from "../../thematics/repositories/thematic.repository";
import { Role } from "../../users/models/users.entity";
import { Profile, ProfileService } from "../../users/services/profile.service";
import { AddUnitsComponent } from "../add-units/add-units.component";
import { EditOrganizationNameComponent } from "../edit-organization-name/edit-organization-name.component";
import { Organization } from "../models/organizations.entity";
import { NewOrganizationComponent } from "../new-organization/new-organization.component";
import { OrganizationMembersRepository } from "../repositories/organization.members.repository";
import { OrganizationRepository } from "../repositories/organization.repository";
import { flattenOrganizationTrees } from "../utils/utils";

@Component({
  selector: "app-organization-edition-form",
  templateUrl: "./organization-edition-form.component.html",
  styleUrl: "./organization-edition-form.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OrganizationEditionFormComponent),
    },
  ],
})
export class OrganizationEditionFormComponent
  implements ControlValueAccessor, OnDestroy, OnInit {
  organization!: Organization;

  organizationFormGroup!: EntityFormGroup<{
    name: string;
    allowedOrganizationIds: string[];
    forbiddenOrganizationIds: string[];
    extraOrganizationMatching: boolean;
    intraOrganizationMatching: boolean;
    forbiddenThematicIds: string[];
    talkerQuota: string;
    unlimitedQuota: boolean;
  }>;

  allThematics: Thematic[] = [];

  forbiddenOrganizationsCheckboxes: Checkbox[] = [];

  forbiddenOrganizationIdsSelection: string[] = [];

  allowedOrganizationIdsSelection: string[] = [];

  quotaPossibilities = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  loading = true;

  settingValues = false;

  private onChange: (value: any) => void = (_: any) => {};

  private onTouched: () => void = () => {};

  destroy$ = new Subject<void>();

  isDisabled = false;

  profile!: Profile;

  canAddUnits = false;

  units!: number;

  // private readonly tracker = inject(MatomoTracker);

  ngOnDestroy() {
    this.destroy$.next();
  }

  registerOnChange(fn: (value: Organization) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: Organization): void {
    const forbiddenOrganizationIds =
      value.forbiddenOrganizations?.map((o) => o.id) ?? [];

    this.organization = value;
    this.units = value.units;
    this.organizationMembersRepository.setOrganizationId(value.id);
    this.organizationFormGroup.controls.name.setValue(value.name, {
      emitEvent: false,
      onlySelf: true,
    });
    this.organizationFormGroup.controls.allowedOrganizationIds.setValue(
      this.organizationFormGroup.controls.allowedOrganizationIds.value.filter(
        (id) => !forbiddenOrganizationIds.includes(id)
      ),
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
    this.organizationFormGroup.controls.forbiddenOrganizationIds.setValue(
      forbiddenOrganizationIds,
      { emitEvent: false }
    );
    this.organizationFormGroup.controls.extraOrganizationMatching.setValue(
      value.extraOrganizationMatching,
      { emitEvent: false }
    );
    this.organizationFormGroup.controls.intraOrganizationMatching.setValue(
      value.intraOrganizationMatching,
      { emitEvent: false }
    );
    this.organizationFormGroup.controls.talkerQuota.setValue(
      value.talkerQuota !== null ? String(value.talkerQuota) : "",
      { emitEvent: false }
    );
    this.organizationFormGroup.controls.unlimitedQuota.setValue(
      value.talkerQuota === null,
      { emitEvent: false }
    );
  }

  setDisabledState(state: boolean) {
    this.isDisabled = state;
  }

  constructor(
    private readonly organizationRepository: OrganizationRepository,
    private readonly organizationMembersRepository: OrganizationMembersRepository,
    private readonly profileService: ProfileService,
    private readonly snackBarService: SnackbarService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly thematicRepository: ThematicRepository,
    private readonly dialog: DialogService
  ) {
    this.organizationFormGroup = this.formBuilder.group({
      name: new FormControl("", {
        validators: Validators.min(1),
        nonNullable: true,
      }),
      allowedOrganizationIds: new FormControl([] as string[], {
        validators: Validators.min(1),
        nonNullable: true,
      }),
      forbiddenOrganizationIds: new FormControl([] as string[], {
        validators: Validators.min(1),
        nonNullable: true,
      }),
      forbiddenThematicIds: new FormControl([] as string[], {
        validators: Validators.min(1),
        nonNullable: true,
      }),
      extraOrganizationMatching: new FormControl(true, {
        validators: Validators.min(1),
        nonNullable: true,
      }),
      intraOrganizationMatching: new FormControl(false, {
        validators: Validators.min(1),
        nonNullable: true,
      }),
      talkerQuota: new FormControl("1", {
        validators: Validators.min(1),
        nonNullable: true,
      }),
      unlimitedQuota: new FormControl(false, {
        validators: Validators.min(1),
        nonNullable: true,
      }),
    });
  }

  ngOnInit() {
    this.organizationRepository
      .findAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe((organizations) => {
        if (organizations.length > 1) {
          this.canAddUnits = true;
        }
      });

    this.profileService
      .getMyProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        this.profile = profile;
      });

    this.thematicRepository
      .getAll()
      .pipe(
        combineLatestWith(this.organizationRepository.findAll()),
        takeUntil(this.destroy$)
      )
      .subscribe(([thematics, allOrganizations]) => {
        this.organizationFormGroup.controls.allowedOrganizationIds.setValue(
          allOrganizations.map((o) => o.id),
          {
            emitEvent: false,
            onlySelf: true,
          }
        );
        this.settingValues = true;
        this.organizationFormGroup.controls.intraOrganizationMatching.setValue(
          this.organization.intraOrganizationMatching,
          {
            emitEvent: false,
            onlySelf: true,
          }
        );
        this.organizationFormGroup.controls.extraOrganizationMatching.setValue(
          this.organization.extraOrganizationMatching,
          {
            emitEvent: false,
            onlySelf: true,
          }
        );
        this.organizationFormGroup.controls.talkerQuota.setValue(
          this.organization.talkerQuota
            ? String(this.organization.talkerQuota)
            : "",
          {
            emitEvent: false,
            onlySelf: true,
          }
        );
        this.organizationFormGroup.controls.unlimitedQuota.setValue(
          this.organization.talkerQuota === null,
          {
            emitEvent: false,
            onlySelf: true,
          }
        );
        this.forbiddenOrganizationIdsSelection =
          this.organization.forbiddenOrganizations?.map((org) => org.id) ?? [];
        this.allThematics = thematics;
        this.loading = false;
        this.settingValues = false;

        this.forbiddenOrganizationIdsSelection =
          this.organization.forbiddenOrganizations?.map(({ id }) => id) ?? [];
        this.allowedOrganizationIdsSelection = allOrganizations
          .map(({ id }) => id)
          .filter(
            (id) =>
              id !== this.organization.id &&
              !this.forbiddenOrganizationIdsSelection.includes(id)
          );
        this.forbiddenOrganizationsCheckboxes = allOrganizations
          .sort((organizationA, organizationB) => {
            if (organizationA.name < organizationB.name) {
              return -1;
            }
            return 1;
          })
          .filter(({ id }) => id !== this.organization.id)
          .map(({ id, name }) => ({
            id,
            key: name.toUpperCase(),
            selected: !!this.forbiddenOrganizationIdsSelection?.includes(id),
          }));
      });

    this.organizationFormGroup.controls.talkerQuota.valueChanges
      .pipe(
        filter((quota) => !!quota),
        tap((value) => {
          // window._mtm.push({ 'event': 'nombre-quota', 'quota': `${value}` });
          this.organizationFormGroup.controls.unlimitedQuota.setValue(false, {
            emitEvent: false,
            onlySelf: true,
          });
        }),
        switchMap((quota) =>
          this.organizationRepository.patch(this.organization.id, {
            talkerQuota:
              quota !== undefined && !quota
                ? null
                : Number(quota)
                ? Number(quota)
                : undefined,
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.snackBarService.pushMessage("Modification enregistrée", "success");
      });

    this.organizationFormGroup.controls.unlimitedQuota.valueChanges
      .pipe(
        tap((unlimitedQuota) => {
          // window._mtm.push({ 'event': 'clic-checkbox', 'checkbox-type': 'Illimité' });
          this.organizationFormGroup.controls.talkerQuota.setValue(
            unlimitedQuota ? "" : "1",
            { emitEvent: false, onlySelf: true }
          );
        }),
        switchMap((unlimitedQuota) =>
          this.organizationRepository.patch(this.organization.id, {
            talkerQuota: unlimitedQuota ? null : 1,
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.snackBarService.pushMessage("Modification enregistrée", "success");
      });

    this.organizationFormGroup.controls.extraOrganizationMatching.valueChanges
    .pipe(
      tap(() => {
        // window._mtm.push({ 'event': 'clic-checkbox', 'checkbox-type': 'Autres organisations' });
      }),
      switchMap(() =>
        this.organizationRepository.patch(this.organization.id, {
          extraOrganizationMatching:
            this.organizationFormGroup.controls.extraOrganizationMatching.value
        })
      ),
      takeUntil(this.destroy$)
    )
    .subscribe(() => {
      this.snackBarService.pushMessage("Modification enregistrée", "success");
    });

    this.organizationFormGroup.controls.intraOrganizationMatching.valueChanges
    .pipe(
      tap(() => {
        // window._mtm.push({ 'event': 'clic-checkbox', 'checkbox-type': 'Se rencontrer' });
      }),
      switchMap(() =>
        this.organizationRepository.patch(this.organization.id, {
          intraOrganizationMatching:
            this.organizationFormGroup.controls.intraOrganizationMatching.value,
        })
      ),
      takeUntil(this.destroy$)
    )
    .subscribe(() => {
      this.snackBarService.pushMessage("Modification enregistrée", "success");
    });
  }

  switchOrganization(organization: Organization) {
    this.router.navigate(["organizations", organization.id]);
  }

  protected readonly capitalize = capitalize;

  editThematics() {
    // this.tracker.trackEvent(TrackerCategory.SETTINGS_PILOT, TrackerAction.CLICK, "manageThematicsButton");
    this.router.navigate(["thematics"], { relativeTo: this.route });
  }

  goToQuestions() {
    this.router.navigate(["questions"]);
  }

  addPilot() {
    // this.tracker.trackEvent(TrackerCategory.SETTINGS_PILOT, TrackerAction.CLICK, "addPilotButton");
    this.router.navigate(["add-pilot"], { relativeTo: this.route });
  }

  updateForbiddenOrganizationsSelection(checkboxes: Checkbox[]): void {
    this.forbiddenOrganizationIdsSelection = checkboxes
      .filter((checkbox) => checkbox.selected)
      .map((checkbox) => checkbox.id);
    this.allowedOrganizationIdsSelection = checkboxes
      .filter((checkbox) => !checkbox.selected)
      .map((checkbox) => checkbox.id);

    this.organizationRepository
      .patch(this.organization.id, {
        allowedOrganizationIds: this.allowedOrganizationIdsSelection,
        forbiddenOrganizationIds: this.forbiddenOrganizationIdsSelection,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.snackBarService.pushMessage("Modification enregistrée", "success");
      });
  }

  addChild() {
    // this.tracker.trackEvent(TrackerCategory.SETTINGS_PILOT, TrackerAction.CLICK, "addOrganizationButton");
    this.dialog
      .open(NewOrganizationComponent, this.organization)
      .pipe(takeUntil(this.destroy$))
      .subscribe((newOrganization) => {
        if (newOrganization?.name) {
          this.organizationRepository.create(newOrganization).subscribe({
            next: (organisationCreated) => {
              this.snackBarService.pushMessage(
                "Organisation créée avec succès",
                "success"
              );
              this.organization.children?.push(organisationCreated);
            },
          });
        }
      });
  }

  editUnits() {
    if (this.profile?.role === Role.Talker && this.profile?.isPilot) {
      this.organizationRepository
        .findAll()
        .pipe(takeUntil(this.destroy$))
        .subscribe((organizations) => {
          this.dialog
            .open(AddUnitsComponent, {
              units: 0,
              organizations: flattenOrganizationTrees(organizations).filter(
                (o) => o.id !== this.organization.id
              ),
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((payload) => {
              if (payload && payload.organizationId) {
                this.organizationMembersRepository
                  .addUnitsFrom(payload.units, payload.organizationId)
                  .subscribe({
                    next: () => {
                      this.snackBarService.pushMessage(
                        "Modification enregistrée",
                        "success"
                      );
                      this.organization.units += Number(payload.units);
                    },
                  });
              }
            });
        });
    }
    if (this.profile?.role === Role.Admin) {
      this.organizationRepository
        .findAll()
        .pipe(takeUntil(this.destroy$))
        .subscribe((organizations) => {
          this.dialog
            .open(AddUnitsComponent, {
              units: 0,
              organizations: flattenOrganizationTrees(organizations).filter(
                (o) => o.id !== this.organization.id
              ),
              isAdmin: true,
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((payload) => {
              if (payload?.organizationId) {
                this.organizationMembersRepository
                  .addUnitsFrom(payload.units, payload.organizationId)
                  .subscribe({
                    next: () => {
                      this.snackBarService.pushMessage(
                        "Modification enregistrée",
                        "success"
                      );
                      this.organization.units += Number(payload.units);
                    },
                  });
              }
              if (!payload?.organizationId && payload) {
                this.organizationMembersRepository
                  .addUnits(payload.units)
                  .subscribe({
                    next: () => {
                      this.snackBarService.pushMessage(
                        "Modification enregistrée",
                        "success"
                      );
                      this.organization.units += Number(payload.units);
                      this.units += Number(payload.units);
                    },
                  });
              }
            });
        });
    }
  }

  editName() {
    this.dialog
      .open(EditOrganizationNameComponent, { name: this.organization.name })
      .pipe(
        takeUntil(this.destroy$),
        filter((newName): newName is string => !!newName),
        switchMap((newName) =>
          this.organizationRepository.patch(this.organization.id, {
            name: newName,
          })
        )
      )
      .subscribe((organization) => {
        this.organization = organization;
        this.snackBarService.pushMessage("Nom modifié avec succès", "success");
      });
  }

  deleteOrganization(): void {
    this.dialog
      .open(ArchiveOrganizationDialog, {
        title: "Voulez-vous vraiment supprimer cet organisation ?",
        organization: this.organization,
        step: 0,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((isModified) => {
        if (isModified) {
          this.router.navigate(["organizations"]);
          this.snackBarService.pushMessage(
            "L'organisation a été supprimée avec succès",
            "success"
          );
        }
      });
  }

  deleteQuota() {
    // window._mtm.push({ 'event': 'nombre-quota', 'quota': 'Supprimer' });
  }

  onSelectQuotaClick() {
    // this.tracker.trackEvent(TrackerCategory.SETTINGS_PILOT, TrackerAction.CLICK, "quotapertalker");
  }

  handleCheckboxChange(){
    // window._mtm.push({ 'event': 'clic-checkbox', 'checkbox-type': 'Illimité' });
    // this.tracker.trackEvent(TrackerCategory.SETTINGS_PILOT, TrackerAction.CLICK, "manageTrainsCheckbox");
  }
}
