import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { IconType } from "../../icon/icon.component";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ]
})
export class ButtonComponent {
  @Input() type!: 'button' | 'submit' | 'reset';

  @Input() title?: string = '';

  @Input() isBig: boolean = false;

  @Input() primary: boolean = false;

  @Input() secondary: boolean = false;

  @Input() danger: boolean = false;

  @Input() outline: boolean = false;

  @Input() black: boolean = false;

  @Input() disabled: boolean = false;

  @Input() icon?: IconType;

  @Input('no-border') noBorder = false;

  @Input('got-notification') gotNotification = false;

  @ViewChild('content') content!: ElementRef<HTMLElement>;

  @Output() readonly onClick = new EventEmitter();

  click() {
    this.onClick.emit();
  }
}
