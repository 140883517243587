import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { filter, map, switchMap, takeUntil, tap } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { safeNumber } from "../../shared/tool-functions/safe-number";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { ThematicRepository } from "../../thematics/repositories/thematic.repository";
import { Talker } from "../../users/models/talker.entity";
import { CreateUser, Role } from "../../users/models/users.entity";
import { NewUserComponent } from "../../users/new-user/new-user.component";
import { ProfileService } from "../../users/services/profile.service";
import {
  CreateOrganization,
  CreateOrganizationForm,
  Organization
} from "../models/organizations.entity";
import { OrganizationRepository } from "../repositories/organization.repository";


@Component({
  selector: 'app-new-organization',
  templateUrl: './new-organization.component.html',
  styleUrls: [ './new-organization.component.scss' ]
})
export class NewOrganizationComponent extends DialogComponent<CreateOrganization, Organization>() implements OnInit, OnDestroy {
  newOrganizationForm!: EntityFormGroup<CreateOrganizationForm>;

  nameTaken = false;

  pilot?: CreateUser;

  pilotId?: string;

  loading = false;

  existingOrganizations: Organization[] = [];

  existingNames: string[] = [];

  existingNamesWithUnits: string[] = [];

  existingTalkerNames: string[] = [];

  existingFormulaNames: string[] = [
    "À la carte - Particulier", 
    "À la carte - Professionnel", 
    "Abonnement Basique",
    "Abonnement Classique",
    "Abonnement Premium",
    "Abonnement Elite"
  ];

  existingTalkers: Talker[] = [];

  organizationUnitsFrom?: Organization;

  childrenOrganizationsIdSelected: string[] = [];

  childrenCheckboxes: Checkbox[] = [];

  forbiddenThematicIdsSelected: string[] = [];

  allowedThematicsCheckboxes: Checkbox[] = [];

  forbiddenOrganizationsCheckboxes: Checkbox[] = [];

  forbiddenOrganizationIdsSelection: string[] = [];

  constructor(
    @Inject(DIALOG_DATA)
    public readonly parent: Organization,
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<CreateOrganization>,
    private readonly profileService: ProfileService,
    private readonly dialog: DialogService,
    private readonly thematicRepository: ThematicRepository,
    private readonly organizationRepository: OrganizationRepository) {
    super(ref);
    this.newOrganizationForm = this.formBuilder.nonNullable.group({
      name: new FormControl('', { validators: Validators.required, nonNullable: true }),
      gotUnits: new FormControl(false, { nonNullable: true }),
      units: new FormControl(0, { validators: Validators.required, nonNullable: true }),
      addUnitsFrom: new FormControl('', { nonNullable: true }),
      talkerQuota: new FormControl(),
      gotPilot: new FormControl(false, { nonNullable: true }),
      gotFormula: new FormControl(false, { nonNullable: true }),
      gotQuota: new FormControl(false, { nonNullable: true }),
      pilot: new FormControl('', { nonNullable: true }),
      gotParent: new FormControl(!!parent, { nonNullable: true }),
      parent: new FormControl(parent?.name?.toUpperCase() ?? '', { nonNullable: true }),
      gotChildren: new FormControl(false, { nonNullable: true }),
      intraOrganizationMatching: new FormControl(false, { nonNullable: true }),
      gotForbiddenOrganizations: new FormControl(false, { nonNullable: true }),
      gotForbiddenThematics: new FormControl(false, { nonNullable: true }),
      subscriptionPlan: new FormControl<string | undefined>(undefined, { nonNullable: true })
    });
  }

  ngOnInit() {
    super.onInit();
    this.organizationRepository.findAll()
      .pipe(
        takeUntil(this.destroy$),
        map(organizations => organizations.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          return 1;
        }))
      )
      .subscribe(organizations => {
        this.existingOrganizations = organizations;
        this.existingTalkers = organizations.flatMap(organization => organization.members).filter(talker => !!talker.personalInformation);
        this.existingTalkerNames = this.existingTalkers.map(talker => talker.fullName);
        this.existingNamesWithUnits = organizations.map(organization => organization.nameWithUnits);
        this.forbiddenOrganizationsCheckboxes = organizations.map(({ id, name }) => ({
          id,
          key: name.toUpperCase(),
          selected: false
        }));
        this.existingNames = organizations.map(({ name }) => name.trim().toUpperCase());
        this.childrenCheckboxes = organizations
          .filter(({ parent }) => !parent)
          .map(organization => ({
            id: organization.id,
            key: organization.name.toUpperCase(),
            selected: false
          }));
      });
    this.newOrganizationForm.controls.name.valueChanges.pipe(
      tap(() => {
        this.loading = true;
      }),
      switchMap((value) => this.organizationRepository.isNameTaken(value)),
      takeUntil(this.destroy$)
    ).subscribe({
      next: isTaken => {
        this.nameTaken = isTaken;
        this.loading = false;
      }
    });

    this.newOrganizationForm.controls.gotChildren.valueChanges
      .pipe(
        filter(value => value),
        takeUntil(this.destroy$)
      ).subscribe(() => {
      this.newOrganizationForm.controls.gotParent.setValue(false);
      this.newOrganizationForm.controls.parent.setValue('');
    });

    this.newOrganizationForm.controls.pilot.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.pilot = this.existingTalkers.find(talker => talker.fullName === value) ?? this.pilot;
          this.pilotId = this.existingTalkers.find(talker => talker.fullName === value)?.id;
        } else {
          this.pilot = undefined;
        }
      });

    this.thematicRepository.getAll().pipe(takeUntil(this.destroy$)).subscribe(thematics => {
      this.allowedThematicsCheckboxes = thematics.map(thematic => ({
        id: thematic.id,
        key: thematic.name,
        selected: true,
      }));
    });

    this.newOrganizationForm.controls.gotParent.valueChanges
      .pipe(
        filter(value => value),
        takeUntil(this.destroy$)
      ).subscribe(() => {
      this.newOrganizationForm.controls.gotChildren.setValue(false);
      this.childrenOrganizationsIdSelected = [];
      this.childrenCheckboxes.forEach(checkbox => {
        checkbox.selected = false;
      });
    });

    this.newOrganizationForm.controls.addUnitsFrom.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((value) => {
      this.organizationUnitsFrom = this.existingOrganizations
        .find(o => value === o.nameWithUnits);
    });

  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get nameWorks(): boolean {
    return this.newOrganizationForm.controls.name.valid && !this.nameTaken && !this.loading;
  }

  get formIsValid(): boolean {
    return this.newOrganizationForm.valid
      && this.nameWorks
      && (!this.newOrganizationForm.controls.units.value || (!!this.organizationUnitsFrom && this.organizationUnitsFrom.units >= this.newOrganizationForm.controls.units.value) || (this.profileService.profile?.role === Role.Admin && !this.organizationUnitsFrom))
      && ((this.gotParent && !this.gotChildren) || (!this.gotParent && this.gotChildren) || (!this.gotParent && !this.gotChildren))
      && ((this.gotPilot && !!this.pilot) || !this.gotPilot)
      && (!this.newOrganizationForm.controls.talkerQuota?.value || this.newOrganizationForm.controls.talkerQuota?.value > 0);
  }

  get gotFormula(): boolean {
    return this.newOrganizationForm.controls.gotFormula.value;
  }

  get gotPilot(): boolean {
    return this.newOrganizationForm.controls.gotPilot.value;
  }

  get gotParent(): boolean {
    return this.newOrganizationForm.controls.gotParent.value;
  }

  get gotChildren(): boolean {
    return this.newOrganizationForm.controls.gotChildren.value;
  }

  get gotUnits(): boolean {
    return this.newOrganizationForm.controls.gotUnits.value;
  }

  get gotQuota(): boolean {
    return this.newOrganizationForm.controls.gotQuota.value;
  }

  get gotForbiddenThematics(): boolean {
    return this.newOrganizationForm.controls.gotForbiddenThematics.value;
  }

  get gotForbiddenOrganizations(): boolean {
    return this.newOrganizationForm.controls.gotForbiddenOrganizations.value;
  }

  get isAdmin(): boolean {
    return this.profileService.profile?.role === Role.Admin;
  }

  addPilot(): void {
    this.dialog.open(NewUserComponent, 'pilot')
      .pipe(takeUntil(this.destroy$))
      .subscribe(newPilot => {
        if (newPilot !== undefined) {
          this.pilot = newPilot;
          this.newOrganizationForm.controls.pilot.setValue(`${ capitalize(newPilot.firstName) } ${ newPilot.lastName.toUpperCase() }`);
        }
      });
  }

  validate(validated: boolean = false): void {
    if (this.formIsValid && validated && !this.nameTaken) {
      super.close({
        name: this.newOrganizationForm.controls.name.value.trim(),
        intraOrganizationMatching: this.newOrganizationForm.controls.intraOrganizationMatching.value,
        units: safeNumber(this.newOrganizationForm.controls.units.value),
        addUnitsFrom: this.organizationUnitsFrom?.id,
        talkerQuota: this.newOrganizationForm.controls.talkerQuota.value === null
          ? this.newOrganizationForm.controls.talkerQuota.value
          : safeNumber(this.newOrganizationForm.controls.talkerQuota.value),
        pilots: !this.pilotId ? [ this.pilot ].filter((pilot): pilot is CreateUser => !!pilot) : [],
        pilotId: this.pilotId,
        extraOrganizationMatching: true,
        parent: this.gotParent ? this.existingOrganizations.find(({ name }) => this.newOrganizationForm.controls.parent.value.toLowerCase() === name)?.id : undefined,
        children: this.gotChildren ? this.childrenOrganizationsIdSelected : undefined,
        forbiddenThematicIds: this.gotForbiddenThematics ? this.forbiddenThematicIdsSelected : undefined,
        forbiddenOrganizationIds: this.gotForbiddenOrganizations ? this.forbiddenOrganizationIdsSelection : undefined,
        subscriptionPlan: this.getPlanSlugByName(this.newOrganizationForm.controls.subscriptionPlan.value ?? '')
      });
    } else {
      super.close();
    }
  }

  getPlanSlugByName(name: string): string {
    switch (name) {
      case "À la carte - Particulier":
        return "personalized-subscription-individual";
      case "À la carte - Professionnel":
        return "personalized-subscription-professional";
      case "Abonnement Basique":
        return "basic-subscription";
      case "Abonnement Classique":
        return "classic-subscription";
      case "Abonnement Premium":
        return "premium-subscription";
      case "Abonnement Elite":
        return "elite-subscription";
      default:
        return "";
    }
  }

  updateChildrenSelection(checkboxes: Checkbox[]): void {
    this.childrenOrganizationsIdSelected = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
  }

  updateAllowedThematicsSelection(checkboxes: Checkbox[]): void {
    this.forbiddenThematicIdsSelected = checkboxes.filter(checkbox => !checkbox.selected).map(checkbox => checkbox.id);
  }

  updateForbiddenOrganizationsSelection(checkboxes: Checkbox[]): void {
    this.forbiddenOrganizationIdsSelection = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
  }
}
