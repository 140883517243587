import { OverlayModule } from "@angular/cdk/overlay";
import { CdkTableModule } from "@angular/cdk/table";
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
  NgTemplateOutlet,
} from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CodeInputModule } from "angular-code-input";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgChartsModule } from "ng2-charts";
import { NgxPermissionsModule, NgxPermissionsService } from "ngx-permissions";

import { ArchiveOrganizationDialog } from "./components/archive-organization-dialog/archive-organization-dialog";
import { ArrowButtonComponent } from "./components/arrow-button/arrow-button.component";
import { AudioPlayerComponent } from "./components/audio-player/audio-player.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ButtonComponent } from "./components/buttons/button/button.component";
import { MonthlyCalendarComponent } from "./components/calendars/monthly-calendar/monthly-calendar.component";
import { WeeklyCalendarComponent } from "./components/calendars/weekly-calendar/weekly-calendar.component";
import { AvailableTrainComponent } from "./components/cards/available-train/available-train.component";
import { CardComponent } from "./components/cards/card/card.component";
import { CardsListComponent } from "./components/cards/cards-list/cards-list.component";
import { ContentCardsListComponent } from "./components/cards/content-cards-list/content-cards-list.component";
import { DynamicsComponent } from "./components/cards/dynamics/dynamics.component";
import { MediaCardComponent } from "./components/cards/media-card/media-card.component";
import { OrganizationCardComponent } from "./components/cards/organization-card/organization-card.component";
import { ThematicCardComponent } from "./components/cards/thematic-card/thematic-card.component";
import { ThematicTrainsComponent } from "./components/cards/thematic-trains/thematic-trains.component";
import { TrainAvailableComponent } from "./components/cards/train-available/train-available.component";
import { TrainCardComponent } from "./components/cards/train-card/train-card.component";
import { UserCardComponent } from "./components/cards/user-card/user-card.component";
import { CGUComponent } from "./components/cgu/cgu.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { CheckmarksComponent } from "./components/checkmarks/checkmarks.component";
import { ConfirmComponent } from "./components/confirm/confirm.component";
import { CongratsComponent } from "./components/congrats/congrats.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { EditableListComponent } from "./components/editable-list/editable-list.component";
import { EmojiSelectorComponent } from "./components/emoji-selector/emoji-selector.component";
import { FavoriteThematicComponent } from "./components/favorite-thematic/favorite-thematic.component";
import { FunnelComponent } from "./components/funnel/funnel.component";
import { GoFurtherLinksComponent } from "./components/go-further-links/go-further-links/go-further-links.component";
import { WelcomeLinksComponent } from "./components/go-further-links/welcome-links/welcome-links.component";
import { IconComponent } from "./components/icon/icon.component";
import { ImageComponent } from "./components/image/image.component";
import { HomePageTabsComponent } from "./components/in-page-tabs/home-page-tabs/home-page-tabs.component";
import { InPageTabsComponent } from "./components/in-page-tabs/in-page-tabs/in-page-tabs.component";
import { InfoComponent } from "./components/info/info.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { NotificationsResumeComponent } from "./components/notifications-resume/notifications-resume.component";
import { CardPaginationComponent } from "./components/pagination/card-pagination/card-pagination.component";
import { PaginationFooterComponent } from "./components/pagination/pagination-footer/pagination-footer.component";
import { TableCustomPaginationComponent } from "./components/pagination/table-custom-pagination/table-custom-pagination.component";
import { TablePaginationComponent } from "./components/pagination/table-pagination/table-pagination.component";
import { PasswordSetterComponent } from "./components/password-setter/password-setter.component";
import { ProfileIconComponent } from "./components/profile-icon/profile-icon.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { RadioButtonsComponent } from "./components/radio-buttons/radio-buttons.component";
import { AppSelectComponent } from "./components/select/select.component";
import { SeparatorComponent } from "./components/separator/separator.component";
import { SliderComponent } from "./components/slider/slider.component";
import { SnackbarComponent } from "./components/snackbar/snackbar.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { StepperComponent } from "./components/stepper/stepper.component";
import { SubscribeMoreComponent } from "./components/subscribe-more/subscribe-more.component";
import { SubscribePageComponent } from "./components/subscribe-page/subscribe-page.component";
import { TableComponent } from "./components/table/table.component";
import { TagsComponent } from "./components/tags/tags.component";
import { TalkerTestimonialQuoteComponent } from "./components/talker-testimonial-quote/talker-testimonial-quote.component";
import { TestEndBannerComponent } from "./components/test-end-banner/test-end-banner.component";
import { TextInputComponent } from "./components/text-input/text-input.component";
import { TimezoneWarningComponent } from "./components/timezone-warning/timezone-warning.component";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { TrainImageComponent } from "./components/train-image/train-image.component";
import { TreeCheckboxComponent } from "./components/tree-checkbox/tree-checkbox.component";
import { TreeCheckmarksComponent } from "./components/tree-checkmarks/tree-checkmarks.component";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { UploadFileService } from "./components/upload-file/upload-file.service";
import { DropdownDirective } from "./directives/dropdown.directive";
import { IsVisible } from "./directives/is-visible.directive";
import { TooltipDirective } from "./directives/tooltip.directive";
import { availableTrainsResolverFactory } from "./resolvers/matomo.factory";
import { MatomoResolver } from "./resolvers/matomo.resolver";
import { CsvService } from "./services/csv.service";

@NgModule({
  imports: [
    AngularSvgIconModule.forRoot(),
    NgIf,
    NgFor,
    ReactiveFormsModule,
    NgClass,
    HttpClientModule,
    RouterModule,
    FormsModule,
    NgForOf,
    NgxPermissionsModule.forChild(),
    OverlayModule,
    CodeInputModule,
    NgTemplateOutlet,
    AsyncPipe,
    NgChartsModule,
    NgOptimizedImage,
    NgStyle,
    CdkTableModule,
  ],
  providers: [
    HttpClient,
    NgxPermissionsService,
    UploadFileService,
    CsvService,
    MatomoResolver,
    { provide: 'availableTrainsResolverFactory', useFactory: availableTrainsResolverFactory }
  ],
  declarations: [
    TextInputComponent,
    ButtonComponent,
    ProgressBarComponent,
    ArrowButtonComponent,
    StepperComponent,
    PasswordSetterComponent,
    SnackbarComponent,
    CardComponent,
    DropdownComponent,
    DropdownDirective,
    CGUComponent,
    CardsListComponent,
    TooltipComponent,
    SpinnerComponent,
    CheckboxComponent,
    UserCardComponent,
    RadioButtonsComponent,
    ProfileIconComponent,
    OrganizationCardComponent,
    CheckmarksComponent,
    ThematicCardComponent,
    TagsComponent,
    UploadFileComponent,
    IsVisible,
    WeeklyCalendarComponent,
    TrainCardComponent,
    ConfirmComponent,
    CongratsComponent,
    TimezoneWarningComponent,
    MonthlyCalendarComponent,
    BreadcrumbComponent,
    TreeCheckmarksComponent,
    TreeCheckboxComponent,
    SeparatorComponent,
    IconComponent,
    TooltipDirective,
    FunnelComponent,
    SliderComponent,
    EditableListComponent,
    ThematicTrainsComponent,
    FavoriteThematicComponent,
    AvailableTrainComponent,
    DynamicsComponent,
    NotificationComponent,
    CardPaginationComponent,
    TablePaginationComponent,
    TableComponent,
    InfoComponent,
    InPageTabsComponent,
    ImageComponent,
    GoFurtherLinksComponent,
    WelcomeLinksComponent,
    HomePageTabsComponent,
    TalkerTestimonialQuoteComponent,
    NotificationsResumeComponent,
    TableCustomPaginationComponent,
    PaginationFooterComponent,
    ArchiveOrganizationDialog,
    AppSelectComponent,
    EmojiSelectorComponent,
    MediaCardComponent,
    AudioPlayerComponent,
    SubscribePageComponent,
    SubscribeMoreComponent,
    ContentCardsListComponent,
    TestEndBannerComponent,
    TrainAvailableComponent,
    TrainImageComponent
  ],
  exports: [
    NgIf,
    NgFor,
    NgForOf,
    NgClass,
    NgStyle,
    NgOptimizedImage,
    TextInputComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    ProgressBarComponent,
    ArrowButtonComponent,
    StepperComponent,
    PasswordSetterComponent,
    SnackbarComponent,
    CardComponent,
    CardsListComponent,
    CheckboxComponent,
    RadioButtonsComponent,
    UserCardComponent,
    ProfileIconComponent,
    OrganizationCardComponent,
    CheckmarksComponent,
    ThematicCardComponent,
    TagsComponent,
    UploadFileComponent,
    WeeklyCalendarComponent,
    TrainCardComponent,
    SpinnerComponent,
    TimezoneWarningComponent,
    MonthlyCalendarComponent,
    DropdownComponent,
    DropdownDirective,
    BreadcrumbComponent,
    TreeCheckmarksComponent,
    SeparatorComponent,
    TooltipDirective,
    IconComponent,
    SliderComponent,
    EditableListComponent,
    ThematicTrainsComponent,
    FavoriteThematicComponent,
    ImageComponent,
    NotificationsResumeComponent,
    AvailableTrainComponent,
    DynamicsComponent,
    NotificationComponent,
    CardPaginationComponent,
    TablePaginationComponent,
    TableComponent,
    InfoComponent,
    InPageTabsComponent,
    GoFurtherLinksComponent,
    WelcomeLinksComponent,
    HomePageTabsComponent,
    TalkerTestimonialQuoteComponent,
    TableCustomPaginationComponent,
    PaginationFooterComponent,
    ArchiveOrganizationDialog,
    EmojiSelectorComponent,
    MediaCardComponent,
    AudioPlayerComponent,
    SubscribePageComponent,
    SubscribeMoreComponent,
    ContentCardsListComponent,
    TestEndBannerComponent,
    TrainAvailableComponent,
    TrainImageComponent
  ],
})
export class SharedModule {}
