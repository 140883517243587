<div *ngIf="canSeePage; else subscribeBlock">
  <div class="grow center-children full-height" *ngIf="loading">
    <app-spinner />
  </div>
  <div *ngIf="!loading" [ngClass]="{'padding-wrapper': !talkerOrganization}" #wrapper>

    <h1 class="margin-bottom">Statistiques & impact</h1>

    <div>
      <div class="margin-bottom-xl" >
        <app-in-page-tabs arrowDown [tabs]="tabs" (clickLink)="goTo($event)"/>
      </div>
      <br/>
      <div class="wrap sticky filter-menu-sticky" [ngClass]="{'sticky-top': talkerOrganization }">
        <div class="center-vertically-by-margin">
          <app-tree-checkmarks *ngIf="showSelection" [trees]="organizationTrees" [isFemaleWord]="true"
                              item-name="Organisation sélectionnée"
                              (changeTreeSelection)="updateTreeSelection($event)"
                              (changeListSelection)="updateOrganizationsSelected($event)"
          />
        </div>
        <div (click)="onThematicSelect()" class="bubble pointer center-vertically-by-margin row gap center-children"
            [dropdownTriggerFor]="thematicDropdown" dropdownPosition="start">Thématique
          <app-icon type="chevron-down"/>
        </div>
        <app-dropdown [closeOnClick]="false" #thematicDropdown>
          <div class="wrap gap base-padding">
            <div class="bubble pointer" (click)="selectThematic(thematic.id)"
                *ngFor="let thematic of thematicCheckboxes">{{capitalize(thematic.key)}}</div>
          </div>
        </app-dropdown>
        <div (click)="onDynamicSelect()" class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin"
            [dropdownTriggerFor]="dynamicsDropdown">Dynamique de groupe
          <app-icon type="chevron-down"/>
        </div>
        <app-dropdown [closeOnClick]="false" #dynamicsDropdown>
          <div class="wrap gap base-padding ">
            <div class="bubble pointer" (click)="selectDynamic(dynamic.id)"
                [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
                *ngFor="let dynamic of dynamicTypeCheckboxes">{{capitalize(dynamic.key)}}</div>
          </div>
        </app-dropdown>
        <form [formGroup]="dateForm" class="wrap gap">
          <app-input title="Du" formControlName="fromDate" type="date"></app-input>
          <app-input title="Au" formControlName="toDate" type="date"></app-input>
        </form>

        <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin" (click)="selectThematic(thematic.id)"
            [style.border-color]="thematic.selected ? '#704c8d': 'grey'"
            *ngFor="let thematic of thematicCheckboxesSelected$.getValue()">{{capitalize(thematic.key)}} <app-icon type="close"/></div>
        <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin" (click)="selectDynamic(dynamic.id)"
            [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
            *ngFor="let dynamic of dynamicTypeCheckboxesSelected$.getValue()">{{capitalize(dynamic.key)}} <app-icon type="close"/></div>
      </div>


      <div>

        <div *ngxPermissionsOnly="'sherpa'" >
          <h2 class="cooper margin-bottom no-margin" #talkerSection>Talkers</h2>

          <div class="chart-section" *ngIf="canSeeStatistics">
            <div class="tile">
              <div class="text-xl text-primary cooper">{{talkersWithTrain.length}} </div>
              <div class=" text-bold">Talkers accompagnés</div>
            </div>
            <div class="tile">
              <div class="text-xl text-primary cooper">{{coveredOrganizationsForSherpa.length}} </div>
              <div class=" text-bold">Organisations différentes</div>
            </div>

          </div>
          <div class="chart-section" *ngIf="canSeeStatistics; else notEnoughData">
            <div class="column gap center-children">
              <p class="text-bold">Répartition par genre</p>
              <app-gender-distribution [reload$]="reload$" [talkers]="talkersWithTrain"/>
            </div>
          </div>


        </div>

        <div *ngxPermissionsExcept="'sherpa'" >
          <h2 class="cooper margin-bottom no-margin" #talkerSection>Talkers</h2>

          <div class="chart-section" *ngIf="canSeeStatistics; else notEnoughData">
            <div class="column gap center-children">
              <p class="text-bold">{{invitedTalkers.length}} invités</p>
              <app-gender-distribution [reload$]="reload$" [talkers]="invitedTalkers"/>
            </div>
            <div class="column gap center-children">
              <p class="text-bold">{{activatedTalkers.length}} inscrits</p>
              <app-gender-distribution [reload$]="reload$" [talkers]="activatedTalkers"/>
            </div>
            <div class="column gap center-children">
              <p class="text-bold">{{talkersWithTrain.length}} talkers</p>
              <app-gender-distribution [reload$]="reload$" [talkers]="talkersWithTrain"/>
            </div>
          </div>
          <div class="chart-section" *ngIf="canSeeStatistics">
            <div class="tile">
              <div class="text-xl text-primary cooper">{{activatedInvitedPercent}} %</div>
              <div class=" text-bold">Inscrits vs. invités</div>
            </div>
            <div class="tile">
              <div class="text-xl text-primary cooper">{{talkerActivatedPercent}} %</div>
              <div class=" text-bold">Talkers vs. inscrits</div>
            </div>
            <div class="tile">
              <div class="text-xl text-primary cooper">{{talkerInvitedPercent}} %</div>
              <div class=" text-bold">Talkers vs. invités</div>
            </div>
          </div>
          <div class="chart-section" *ngIf="canSeeStatistics">
            <div class="column gap center-children">
              <p class="text-bold">Historique</p>
              <app-talkers-historical [reload$]="reload$" [dateIntervalSelection$]="selectedInterval$"
                                      [talkers$]="talkers$"></app-talkers-historical>
            </div>
          </div>


        </div>
        <h2 class="cooper margin-bottom" #commitmentSection >Engagement</h2>
        <div class="chart-section" *ngIf="canSeeStatistics; else notEnoughData">
          <div class="tile">
            <div class="text-xl text-primary cooper">{{trainsInProgress}}</div>
            <div class=" text-bold">parcours en cours</div>
          </div><div class="tile">
          <div class="text-xl text-primary cooper">{{trainsDone}}</div>
          <div class=" text-bold">parcours terminés</div>
        </div>
          <div class="tile">
            <div class="text-xl text-primary cooper">{{sessionsDone}}</div>
            <div class=" text-bold">séances réalisées</div>
          </div>
          <div class="tile" *ngIf="hoursDone">
            <div class="row text-bottom">
              <div class="text-large cooper text-primary tiny-margin-right">+ de</div>
              <div class="text-xl text-primary cooper">{{hoursDone}}</div>
              <div class="text-large cooper text-primary"> h</div>
            </div>
            <div class=" text-bold">de développement personnel dispensées grâce à We talk</div>
          </div>
        </div>
        <div class="chart-section" *ngIf="canSeeStatistics">
          <div class="column gap center-children">
            <p class="text-bold">Avancement des parcours</p>
            <app-trains-progression [trainMemberships]="trainMemberships$.getValue()" [reload$]="reload$"
            ></app-trains-progression>
          </div>

          <div class="column gap center-children">
            <p class="text-bold">Parcours par dynamique</p>
            <app-dynamic-type-distribution [trainMemberships]="trainMemberships$.getValue()" [reload$]="reload$"
            ></app-dynamic-type-distribution>
          </div>

          <div class="column gap center-children">
            <p class="text-bold">Parcours par talker</p>
            <app-trains-per-talker [trainMemberships]="trainMemberships$.getValue()" [reload$]="reload$"
            ></app-trains-per-talker>
          </div>
        </div>

        <div class="chart-section" *ngIf="canSeeStatistics">
          <div class="column gap center-children">
            <p class="text-bold">Parcours par thématique</p>
            <app-thematic-distribution [trainMemberships]="trainMemberships$.getValue()" [reload$]="reload$"
                                      [thematics]="thematics$.getValue()"></app-thematic-distribution>
          </div>
        </div>

        <div *ngxPermissionsExcept="'sherpa'">
          <h2 class="cooper margin-bottom">Sherpas</h2>
          <div class="chart-section" *ngIf="canSeeStatistics; else notEnoughData">
            <div class="tile">
              <div class="text-xl text-primary cooper">{{sherpasInvolved}}</div>
              <div class=" text-bold">Sherpas mobilisés</div>
            </div>
            <div class="tile">
              <div class="text-xl text-primary cooper">{{speechSherpasInvolved}}</div>
              <div class=" text-bold">Sherpas pour Parole en groupe</div>
            </div>
            <div class="tile">
              <div class="text-xl text-primary cooper">{{coachingSherpasInvolved}}</div>
              <div class=" text-bold">Sherpas pour Coaching en groupe</div>
            </div>
          </div>
        </div>
        <h2 class="cooper margin-bottom" #satisfactionSection>Satisfaction</h2>
        <div class="chart-section" *ngIf="canSeeStatistics && canSeePostTrainData; else notEnoughResponse">
          <div class="tile">
            <div class="text-xl text-primary cooper">{{sessionSatisfactionPercent}}%</div>
            <div class=" text-bold">Satisfaction</div>
            <div>vis à vis des séances</div>
          </div>
          <div class="tile">
            <div class="text-xl text-primary cooper">{{sherpaSatisfactionPercent}}%</div>
            <div class=" text-bold">Satisfaction</div>
            <div>vis à vis des sherpas</div>
          </div>
          <div class="tile">
            <div class="text-xl text-primary cooper">{{relativesRecommendationPercent}}%</div>
            <div class=" text-bold">Recommandations</div>
            <div>auprès des proches</div>
          </div>
          <div class="tile">
            <div class="text-xl text-primary cooper">{{wantToDoAnotherTrainPercent}}%</div>
            <div class=" text-bold">Souhait</div>
            <div>de faire un parcours supplémentaire</div>
          </div>
        </div>

        <ng-container  *ngxPermissionsOnly="'admin'">
        <div *ngIf="canSeeStatistics" class="feedback-container margin-top">
          <div class="row start gap center-children-vertically margin-left">
            <app-icon type="info"></app-icon>
            <div>Cette section n'est pas visible par les Pilotes.</div>
          </div>
          <div class="emoji-section">
            <div class="left-part">
              <div>
                <div class="text-xl text-primary cooper">{{ feedbackStats?.averageRating }} /5</div>
                <div class="text-bold">Satisfaction</div>
                <div>vis à vis de la plateforme</div>
              </div>
            </div>
            <div class="center-part row space-between">
              <div class="emoji-item-container">
                <app-icon size="50" [type]="'sad-emoji'" [color]="getEmojiColorByType('sad-emoji')"></app-icon>
                <div class="rating">{{ getLatestRating(1) }}</div>
              </div>
              <div class="emoji-item-container">
                <app-icon size="50" [type]="'woozy-emoji'" [color]="getEmojiColorByType('woozy-emoji')"></app-icon>
                <div class="rating">{{ getLatestRating(2) }}</div>
              </div>
              <div class="emoji-item-container">
                <app-icon size="50" [type]="'neutral-emoji'" [color]="getEmojiColorByType('neutral-emoji')"></app-icon>
                <div class="rating">{{ getLatestRating(3) }}</div>
              </div>
              <div class="emoji-item-container">
                <app-icon size="50" [type]="'happy-emoji'" [color]="getEmojiColorByType('happy-emoji')"></app-icon>
                <div class="rating">{{ getLatestRating(4) }}</div>
              </div>
              <div class="emoji-item-container">
                <app-icon size="50" [type]="'laughy-emoji'" [color]="getEmojiColorByType('laughy-emoji')"></app-icon>
                <div class="rating">{{ getLatestRating(5) }}</div>
              </div>
            </div>
            <div class="right-part">
              <app-button icon="upload" [outline]="true" (click)="downloadCsv()">Exporter</app-button>
            </div>
        </div>
      </div>
      </ng-container>

        <h2 class="cooper margin-bottom">Résultat</h2>
        <div class="chart-section" *ngIf="canSeePostTrainData; else notEnoughResponse">
          <div class="column gap center-children full-width">
            <p class="text-bold">Atteinte des objectifs fixés</p>
            <div class="full-width column" *ngFor="let goalStep of goalsAchievements">
              <div class="row gap">
                <div class="grow">{{goalStep.label}}</div>
                <div class="row gap">
                  <div>{{goalStep.responses}} réponses:</div>
                  <div class="text-bold">{{goalStep.percent}} %</div>
                </div>
              </div>
              <div class="horizontal-bar full-width">
                <div [style]="{ width: goalStep.percent + '%', backgroundColor: goalStep.color }" class="inner-bar"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="chart-section" *ngIf="canSeeStatistics && canSeePostTrainData">
          <div class="column gap center-children full-width">
            <p class="text-bold">Progrès sur la thématique abordée</p>
            <div class="row gap full-width">
              <div class="grow center-children column gap" *ngFor="let value of thematicProgress; let index = index;">
                <div class="text-bold">{{value.percent}} %</div>
                <div class="mobile-hidden">{{value.responses}} rép.</div>
                <div class="vertical-bar">
                  <div [style]="{ height: value.percent + '%', marginTop: value.marginTop, backgroundColor: '#98CD6C' }"
                      class="inner-bar"></div>
                </div>
                <div>{{index}}</div>
              </div>
            </div>
            <div class="row full-width base-padding">
              <div class="grow">Pas du tout</div>
              <div>Énormément</div>
            </div>
          </div>
        </div>
        <h2 class="cooper margin-bottom" #impactSection>Impact</h2>
        <app-info *ngIf="canSeeStatistics" title="À propos de l’impact">Ces impacts concernent, d’une part, le bien-être psychologique du
          bénéficiaire dans son intégralité (hédonique comme endémonique, et non particulièrement lié à sa vie
          professionnelle), et d’autre part, sa relation au travail (engagement, performance à l’échelle de l’individu et à
          l’échelle de l’entreprise, risques psychosociaux).
        </app-info>

        <br/>
        <div *ngIf="canSeeStatistics; else notEnoughData;">
          <div class="chart-section margin-top" *ngFor="let result of diagnosticScores">
            <app-typeform-score-bar-chart class="full-width" [result]="result"/>
          </div>
        </div>
      </div>



    </div>
  </div>

  <ng-template #notEnoughData>
    <app-info >Les statistiques n'apparaissent qu'à partir du
      moment où 5 collaborateurs inscrits ont finalisé au moins un parcours We talk.
    </app-info>
  </ng-template>

  <ng-template #notEnoughResponse>
    <app-info >Les statistiques de cette section n'apparaissent qu'à partir du
      moment où au moins 1 collaborateur à fait son bilan de parcours
    </app-info>
  </ng-template>
</div>

<ng-template #subscribeBlock>
  <app-subscribe-page [height]="'85dvh'"></app-subscribe-page>
</ng-template>