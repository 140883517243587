import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Subject, takeUntil } from "rxjs";

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { getPhoneCodes, PhoneCode } from "../../shared/tool-functions/phone.number";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { PhoneNumberValidatorFactory } from "../../shared/validators/phoneNumberValidator";
import { ProfileService } from "../../users/services/profile.service";

@Component({
    selector: 'app-orientation',
    templateUrl: './orientation.component.html',
    styleUrls: ['./orientation.component.scss']
})

export class OrientationComponent implements OnInit {
    orientationForm!: EntityFormGroup<{ phoneNumber: string, orientationMessage: string, timeSelection: string }>;

    loading = false;
  
    phoneRegionCode: PhoneCode = {
      code: 33,
      region: 'FR'
    };

    selectedOption: string = '';

    errorMessages: string[] = [];

    profile: any;

    destroy$ = new Subject<void>();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly profileService: ProfileService,
        private readonly snackbarService: SnackbarService,
        private readonly router: Router,
    ) {

    }

    ngOnInit(): void {
       this.route.data
        .pipe(
            map(data => data.profile),
            takeUntil(this.destroy$)

        ).subscribe((profile: any) => {
            this.profile = profile;
            const fullPhoneNumber = profile?.personalInformation?.phoneNumber;
            if (fullPhoneNumber) {
                let phoneCodeFound = getPhoneCodes().find(phoneCode => phoneCode.code === Number(fullPhoneNumber.slice(1, fullPhoneNumber.length - 10)));
                if (!phoneCodeFound) {
                  phoneCodeFound = getPhoneCodes().find(phoneCode => phoneCode.code === Number(fullPhoneNumber.slice(1, fullPhoneNumber.length - 9)));
                }
                this.phoneRegionCode = phoneCodeFound ?? this.phoneRegionCode;
          
                this.orientationForm = this.formBuilder.group({
                    phoneNumber: new FormControl(fullPhoneNumber.slice(String(this.phoneRegionCode.code).length + 1) || '', { nonNullable: true,  validators: [Validators.required, Validators.pattern(/^\s*\S.*$/)]  }),
                    orientationMessage: new FormControl('', { nonNullable: true,  validators: [Validators.required, Validators.pattern(/^\s*\S.*$/)] }),
                    timeSelection: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern(/^\s*\S.*$/)] })
                  });              
                } else {
                this.orientationForm = this.formBuilder.group({
                    phoneNumber: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern(/^\s*\S.*$/)] }),
                    orientationMessage: new FormControl('', { nonNullable: true,  validators: [Validators.required, Validators.pattern(/^\s*\S.*$/)] }),
                    timeSelection: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern(/^\s*\S.*$/)] })
                  });
              }
        });
    }

    selectPhoneRegionCode(code: PhoneCode) {
        this.phoneRegionCode = code;
    }

    validate(isValidated: boolean): void {
        if (this.formIsValid) {
          this.loading = true;
            this.profileService.orientMe({
                 message: this.orientationForm.controls?.orientationMessage?.value?.trim(), 
                 phoneNumber: this.fullPhoneNumber, 
                 callTimePreference: this.orientationForm.controls.timeSelection.value }).subscribe({
                  next: () => {
                    this.loading = false;
                    this.snackbarService.pushMessage('Votre demande a bien été envoyée', 'success');
                    this.router.navigate(['/home']);
                  },
                  error: () => {
                    this.loading = false;
                    this.snackbarService.pushMessage('Une erreur est survenue', 'error');
                  },
                });
        }
    }

    selectOption(option: string) {
        this.selectedOption = option;
        this.orientationForm.controls.timeSelection.setValue(option);
    }

    isValid(): boolean {
        this.errorMessages = [];

        if (!this.orientationForm.controls.orientationMessage.valid) {
          this.errorMessages.push('Message manquant');
        }
        if (!this.orientationForm.controls.phoneNumber.valid) {
          this.errorMessages.push('Numéro de téléphone manquant');
        }
        if (!this.orientationForm.controls.timeSelection.valid) {
            this.errorMessages.push('Préférence de temps manquante');
        }

        return !this.errorMessages.length;
    }

    get fullPhoneNumber(): string {
        return `+${ this.phoneRegionCode.code }${ this.orientationForm.controls.phoneNumber?.value }`;
      }
  
    get phoneWork(): boolean {
        const regionValidator = PhoneNumberValidatorFactory(this.phoneRegionCode.region);
        return !regionValidator(this.fullPhoneNumber);
      }

      get formIsValid(): boolean {
        return this.orientationForm.valid;
      }
}