import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, combineLatestWith, filter, Subject, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

import { BreadcrumbInput } from "../shared/components/breadcrumb/breadcrumb.component";
import { Role } from "../users/models/users.entity";
import { Profile, ProfileService } from "../users/services/profile.service";

import { Organization } from "./models/organizations.entity";
import { OrganizationStoreService } from "./services/organization.store.service";

@Component({
  selector: 'app-my-organization',
  templateUrl: './my-organization.component.html',
  styleUrls: [ './my-organization.component.scss', ]
})
export class MyOrganizationComponent implements OnInit, OnDestroy {
  public profile!: Profile;

  public organization!: Organization;

  private readonly destroy$ = new Subject<void>();

  public breadcrumbs$ = new BehaviorSubject<BreadcrumbInput[]>([]);

  constructor(private readonly profileService: ProfileService,
              private readonly router: Router,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit() {
    this.profileService.getMyProfile()
      .pipe(
        combineLatestWith(this.route.data
          .pipe(
            map(data => data.organization),
            filter((organization): organization is Organization => organization instanceof Organization)
          )
        ),
        takeUntil(this.destroy$))
      .subscribe(([ profile, organization ]) => {
        this.profile = profile;
        if (!organization) {
          this.router.navigate([ 'home' ]);
        } else {
          this.organization = organization;
          this.breadcrumbs$.next(this.getOrganizationBreadcrumbs(organization));
        }
      });


  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getOrganizationBreadcrumbs(organization: Organization): BreadcrumbInput[] {
    const result: BreadcrumbInput[] = [{ displayName: organization.name, url: organization.id }];
    let currentOrganization = organization;
    let depth = 0;
    let gotRightsOnOrganization = true;
    while (currentOrganization.parent && depth <= 2) {
      if (this.profile?.role === Role.Talker && this.profile?.organizationAssociations?.map(o => o.organization?.id)?.includes(currentOrganization.id)) {
        gotRightsOnOrganization = false;
      }
      currentOrganization = currentOrganization.parent;
      result.push({
        displayName: depth < 2 ? currentOrganization.name : '...',
        url: gotRightsOnOrganization && organization?.id && depth < 2 ? currentOrganization.id : undefined
      });
      depth += 1;
    }
    return result;
  }

  goToOrganization(id: string) {
    this.router.navigate([ 'organizations', id ]);
  }
}
