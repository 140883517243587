<div class="full-width full-height mask center-children" *ngIf="loading">
  <app-spinner></app-spinner>
</div>
<div #funnel class="column fade-right-reverse"
     [class.sized-wrapper]="!isFullPage"
     [class.fade-right]="leaving">

  <div class="top-part row" [class.full-page]="isFullPage" *ngIf="!isMeeting">
    <div class="grow pointer margin-right">
      <div class="center-children row" (click)="goToPreviousPage()">
        <app-icon type="chevron-left" class="margin-right"/>
        {{previousMessage}}
      </div>
    </div>
  </div>
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
  <div class="bottom-part center-children" *ngIf="canValidate && !isMeeting">
    <app-button [primary]="isPurple" *ngIf="isTrainAssignedSherpaOrAdminOrTalker" (click)="validate()">{{validateMessage ?? 'Valider'}}</app-button>
    <app-button title="J'accompagne ce groupe" *ngIf="train && !train.sherpa && isSherpa" (click)="accompanyGroup()">J'accompagne ce groupe</app-button>
  </div>
  <div class="bottom-part center-children column" *ngIf="showTakenTrainMessage">
    <p class="taken-train-text">Un autre Sherpa s'est déjà positionné sur ce parcours !</p>
    <p class="view-train-text">N'hésitez pas à <a (click)="goToTrains()">aller voir</a> si d'autres parcours sont disponibles</p>
  </div>
  <div class="bottom-part center-children column" *ngIf="isTrainPage && train && !isSherpa && !canValidate">
    <p class="taken-train-text">Nous sommes désolés, la dernière place sur ce parcours a été comblée.</p>
    <p class="view-train-text">Pas d'inquiétude, vous pouvez dès maintenant <a (click)="goToThematics()">ouvrir un nouveau parcours</a> en selectionnant</p>
    <p class="view-train-text">la thématique puis la date de votre choix.</p>
  </div>
</div>


