import { InPageTab } from "../../shared/components/in-page-tabs/in-page-tabs/in-page-tabs.component";

export const tabs: InPageTab[] = [
    {
      icon: "users",
      color: "#8C5FB0",
      title: "Talkers",
      description: "",
      link: "talkers"
    },
    {
      icon: "check-dot",
      color: "#6AA33E",
      title: "Engagement",
      description: "",
      link: "commit"
    },
    {
      icon: "gauge",
      color: "#E37055",
      title: "Satisfaction",
      description: "",
      link: "satisfaction"
    },
    {
      icon: "heart-file",
      color: "#FF8B05",
      title: "Impact",
      description: "",
      link: "impact"
    },
  ];