import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { Talker } from "../../../users/models/talker.entity";
import { Gender } from "../../../users/models/users.entity";
import { DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-gender-distribution',
  templateUrl: './gender-distribution.component.html'
})
export class GenderDistributionComponent extends AbstractChartComponent<'doughnut'> implements AfterViewInit, OnChanges {
  @Input() talkers!: Talker[];

  type: 'doughnut' = 'doughnut';

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        }
      }
    }
  };

  ngAfterViewInit(): void {
    this.chartData.labels = [ 'Homme', 'Femme', 'Non précisé' ];
    this.chart?.update();
  }


  ngOnChanges() {
    super.onChanges();
  }

  updateData() {
    this.chartData.datasets = [
      {
        data: [
          this.talkers.filter(talker => talker.gender === Gender.MAN).length,
          this.talkers.filter(talker => talker.gender === Gender.WOMAN).length,
          this.talkers.filter(talker => talker.gender === Gender.OTHER).length,
        ],
        backgroundColor: this.getDefaultColors().slice(0, 3)
      }

    ];
  }

}
