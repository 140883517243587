import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
// import { MatomoTracker } from 'ngx-matomo-client';
import {
  BehaviorSubject,
  combineLatestWith,
  filter,
  forkJoin,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";

import { DemandsService } from "../../authentication/services/demands.service";
import { OrganizationRepository } from "../../organizations/repositories/organization.repository";
import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Train } from "../../trains/models/train.entity";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { Talker } from "../../users/models/talker.entity";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { mediumCategoryColors } from "../colors";
import { CreateTrainService } from "../create-train.service";
import { ThematicCategory, ThematicCategorySlug } from "../models/category.model";
import { DynamicType } from "../models/dynamic.model";
import { Thematic } from "../models/thematic.model";
import { ThematicRepository } from "../repositories/thematic.repository";

@Component({
  selector: 'app-thematics-list',
  templateUrl: './thematics-list.component.html',
  styleUrls: [ './thematics-list.component.scss' ]
})
export class ThematicsListComponent implements OnInit, OnDestroy {

  private readonly update$ = new Subject<void>();

  allThematics: Thematic[] = [];

  thematicsShown: Thematic[] = [];

  thematicsShownPartOne: Thematic[] = [];

  remainingThematics: Thematic[] = [];

  available$ = new Subject<boolean>();

  availableTrains$: BehaviorSubject<Train[]> = new BehaviorSubject<Train[]>([]);

  destroy$ = new Subject<void>();

  quotaExceeded = false;

  noUnitAvailable = false;

  loading = true;

  categories: ThematicCategory[] = [];

  categoryCheckboxes: Checkbox<ThematicCategorySlug>[] = [];

  categoryCheckboxesSelected: Checkbox<ThematicCategorySlug>[] = [];

  onlyFavorites = false;

  favoriteThematics: Thematic[] = [];

  dynamicTypeCheckboxes: Checkbox<DynamicType>[] = [ {
    id: DynamicType.COACHING,
    key: "Coaching en groupe",
    selected: false,
  },
    {
      id: DynamicType.SPEECH,
      key: "Parole en groupe",
      selected: false,
    } ];

  dynamicTypeCheckboxesSelected: Checkbox<DynamicType>[] = [];

  alreadyAskForMoreQuota = false;

  alreadyAskForMoreUnits = false;

  isPilot = false;

  thematicsOrdered: Thematic[] = [];

  leavingFunnel = false;

  enteringFunnel = false;

  public readonly cardTrackBy = (_: number, card: Thematic) => card.id;

  // private readonly tracker = inject(MatomoTracker);

  constructor(private readonly thematicRepository: ThematicRepository,
              private readonly router: Router,
              private readonly profileService: ProfileService,
              private readonly organizationRepository: OrganizationRepository,
              private readonly demandService: DemandsService,
              private readonly createTrainService: CreateTrainService,
              public readonly funnelService: FunnelService,
              private readonly trainRepository: TrainRepository) {
  }


  ngOnInit(): void {
    this.thematicRepository.getThematicsOrderByTrains()
      .pipe(
        combineLatestWith(this.thematicRepository.getAvailableOnes(), this.thematicRepository.getCategories()),
        takeUntil(this.destroy$))
      .subscribe(([ thematicsOrdered, allThematics, categories ]) => {
        this.thematicsOrdered = thematicsOrdered.slice(0, 6);
        this.allThematics = allThematics;
        this.filter();
        this.categoryCheckboxes = categories.map(category => ({
          selected: false,
          id: category.slug,
          key: category.name
        }));
      });

    // this.funnelService.leavingFunnel$
    //   .pipe(
    //     tap(() => {
    //       this.leavingFunnel = true;
    //     }),
    //     delay(this.funnelService.ANIMATION_DELAY),
    //     tap(() => {
    //       this.leavingFunnel = false;
    //     }),
    //     takeUntil(this.destroy$))
    //   .subscribe();

    this.createTrainService.clear();

    this.available$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
      });


    this.update$
      .pipe(
        startWith(() => {
        }),
        switchMap(() => this.thematicRepository.getAvailableOnes()),
        tap(thematics => {
          this.allThematics = thematics;
        }),
        takeUntil(this.destroy$),
        switchMap(() => this.profileService.getMyProfile()),
        tap(profile => {
          if (profile?.role !== Role.Talker) {
            this.available$.next(false);
          } else {
            if (profile.isPilot) {
              this.isPilot = true;
            }
            this.favoriteThematics = profile.favoriteThematics ?? [];
          }
        }),
        filter((profile): profile is Talker => profile?.role === Role.Talker),
        switchMap(() => forkJoin([ this.trainRepository.getAvailableTrains(), this.organizationRepository.canConsumeUnit() ]))
      )
      .subscribe(
        ([ availableTrains, canConsumeResult ]) => {
          this.availableTrains$.next(availableTrains);

          this.quotaExceeded = !canConsumeResult.quota;
          this.noUnitAvailable = !canConsumeResult.unitAvailable;
          this.alreadyAskForMoreQuota = canConsumeResult.alreadyAskForMoreQuota;
          this.alreadyAskForMoreUnits = canConsumeResult.alreadyAskForMoreUnits;

          this.available$.next(true);
        }
      );
  }

  askForMoreQuota(): void {
    this.demandService.askForMoreQuota((this.profileService.profile as Talker)?.organization?.id ?? "").subscribe(() => {
      this.alreadyAskForMoreQuota = true;
    });
  }

  askForMoreUnits(): void {
    this.demandService.askForMoreUnits((this.profileService.profile as Talker)?.organization?.id ?? "").subscribe(() => {
      this.alreadyAskForMoreUnits = true;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  clickOnThematic(thematic: Thematic, isPopular = false) {
    // window._mtm.push({ 'event': 'clic-thematique', 'nom-parcours': thematic.title });
    // this.tracker.trackEvent(TrackerCategory.THEMATICS_TALKER, TrackerAction.CLICK, `${isPopular ? 'mostpopularThematic' : `${slug}Thematic` }`);
    this.router.navigate([ 'thematics', thematic.slug ]);
  }

  addThematic() {
    this.router.navigate([ 'thematics', 'new', 'edit' ]);
  }

  selectCategory(slug: string) {
    const category = this.categoryCheckboxes.find(c => c.id === slug);
    if (category) {
      category.selected = !category.selected;
    }
    this.categoryCheckboxesSelected = this.categoryCheckboxes.filter(checkbox => checkbox.selected);

    this.filter();
  }

  selectDynamic(type: string) {
    const dynamic = this.dynamicTypeCheckboxes.find(c => c.id === type);
    if (dynamic) {
      dynamic.selected = !dynamic.selected;
    }
    this.dynamicTypeCheckboxesSelected = this.dynamicTypeCheckboxes.filter(checkbox => checkbox.selected);
    this.filter();
  }

  selectFavorite() {
    this.onlyFavorites = !this.onlyFavorites;
    // this.tracker.trackEvent(TrackerCategory.THEMATICS_TALKER, TrackerAction.CLICK, 'favoriteIcon');
    this.filter();
  }

  filter() {
    this.thematicsShown = this.allThematics
      .filter(thematic =>
        !this.categoryCheckboxes.filter(checkbox => checkbox.selected).length || this.categoryCheckboxes.filter(checkbox => checkbox.selected)
          .map(checkbox => checkbox.id).includes(thematic.category?.slug as ThematicCategorySlug)
      )
      .filter(thematic => !this.dynamicTypeCheckboxes.filter(d => d.selected).length || this.dynamicTypeCheckboxes.filter(d => d.selected).length === 2 || thematic.dynamics.map(d => d.type).includes(this.dynamicTypeCheckboxes.filter(d => d.selected)[0].id))
      .filter(thematic => !this.onlyFavorites || this.favoriteThematics.map(ft => ft.id).includes(thematic.id));
      this.thematicsShownPartOne = this.thematicsShown.slice(0, 18);
      this.remainingThematics = this.thematicsShown.slice(18);
  }

  protected readonly mediumCategoryColors = mediumCategoryColors;

  protected readonly capitalize = capitalize;

  protected readonly DynamicType = DynamicType;

  getThematicProblem(thematic: Thematic): string | undefined {
    if (thematic.isArchived) {
      return 'Thématique archivée';
    }
    if (thematic.dynamics?.length === 0) {
      return 'Thématique sans dynamiques';
    }
    const speechDynamic = thematic.dynamics?.find(dynamic => dynamic.type === DynamicType.SPEECH);
    if (speechDynamic?.isArchived === false && speechDynamic?.sherpasAllowed?.length === 0) {
      return 'Parole en groupe sans sherpa';
    }

    const coachingDynamic = thematic.dynamics?.find(dynamic => dynamic.type === DynamicType.COACHING);
    if (coachingDynamic?.isArchived === false && coachingDynamic?.sherpasAllowed?.length === 0) {
      return 'Parole en groupe sans sherpa';
    }

    return undefined;
  }

  onSelectCategoryClick(){
    // this.tracker.trackEvent(TrackerCategory.THEMATICS_TALKER, TrackerAction.CLICK, 'categoryFilter');
  }

  onSelectDynamicClick(){
    // this.tracker.trackEvent(TrackerCategory.THEMATICS_TALKER, TrackerAction.CLICK, 'dynamicFilter');
  }

  goToOrientation(){
    this.router.navigate(["home", "orientation"]);
  }
}
