import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
// import { MatomoTracker } from "ngx-matomo-client";
import { BehaviorSubject, forkJoin, Observable, Subject, takeUntil } from "rxjs";

import { EditConstantsComponent } from "../../admin/edit-constants/edit-constants.component";
import { EditTimeslotsComponent } from "../../admin/edit-timeslots/edit-timeslots.component";
import { ConfigurationRepository } from "../../admin/repositories/configuration.repository";
import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { Organization } from "../../organizations/models/organizations.entity";
import { OrganizationRepository } from "../../organizations/repositories/organization.repository";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { ProfileStore } from "../../shared/services/profile.storage.service";
import { StorageService } from "../../shared/storage/storage.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Train } from "../../trains/models/train.entity";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";

import { OnBoardingData, OnBoardingDialogComponent } from "./onboarding-dialog/onboarding-dialog.component";

interface ImagesLinks {
  "tree-in-head": string;
  coach: string;
  train: string;
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  firstName!: string;

  role!: Role;

  smsBalance?: number;

  destroy$ = new Subject<void>();

  checkForSherpaAlert$ = new Subject<void>();

  trains: Train[] = [];

  trainsCompleted: Train[] = [];

  availableTrains: Train[] = [];

  loading = true;

  resetDataLoading = false;

  quotaDemandsCount = 0;

  isPilot = false;

  isTalker = false;

  hasDoneInitialResponse = false;

  managedOrganizations: Organization[] = [];

  firstOrganizationWithQuotaDemand?: Organization;

  isSendingEmailActivated = true;

  canDeactivateSendingEmail = false;

  canResetData: boolean;

  private readonly imagesLinksSubject = new BehaviorSubject<ImagesLinks>({
    "tree-in-head": '',
    coach: '',
    train: ''
  });
  
  imagesLinks$: Observable<ImagesLinks> = this.imagesLinksSubject.asObservable();

  // private readonly tracker = inject(MatomoTracker);

  playerVars = {
    controls: 1,
    showinfo: 0,
    rel: 0,
    modestbranding: 1,
    playsinline: 1,
  };

  constructor(
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly configurationRepository: ConfigurationRepository,
    private readonly trainRepository: TrainRepository,
    private readonly organizationRepository: OrganizationRepository,
    private readonly dialog: DialogService,
    private readonly snackbarService: SnackbarService,
    private readonly titleService: Title,
    private readonly storageService: StorageService,
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    private profileStore: ProfileStore,
    private sanitizer: DomSanitizer
  ) {
    this.canDeactivateSendingEmail = this.appConfig.canDeactivateSendingEmail === "true";
    this.canResetData = this.appConfig.canResetData === "true";
  }

  ngOnInit(): void {
    this.titleService.setTitle("We Talk");
    this.profileService
      .getObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        this.firstName = capitalize(profile.firstName);
        this.isTalker = profile.role === Role.Talker;

        this.profileStore.saveProfile(profile);

        this.hasDoneInitialResponse =
          profile.role === Role.Talker && !!profile.initialFormResponse;
        this.role = profile.role;
        this.managedOrganizations =
          profile.role === Role.Talker
            ? profile.organizationAssociations
                ?.map((association) => association.organization)
                ?.filter((o): o is Organization => !!o)
            : [];
        this.isPilot = profile.role === Role.Talker && profile.isPilot;

        if (this.managedOrganizations.length) {
          forkJoin(
            this.managedOrganizations.map((organization) => this.organizationRepository.getQuotaDemands(organization.id)
            )
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe((demandCounts) => {
              this.quotaDemandsCount = demandCounts.reduce(
                (acc, count, index) => {
                  if (count) {
                    this.firstOrganizationWithQuotaDemand =
                      this.managedOrganizations[index];
                  }
                  return acc + count;
                },
                0
              );
            });
        }

        this.profileService.getHomeImagesLinks().subscribe((images) => {
          const imagesLinks: ImagesLinks = {
            "tree-in-head": images["tree-in-head"] || '',
            coach: images.coach || '',
            train: images.train || ''
          };
          this.imagesLinksSubject.next(imagesLinks);
        });

        this.trainRepository.getMyTrains().subscribe((trains) => {
          if (this.role === Role.Admin) {
            this.trains = trains.filter((_, index) => index < 2);
          } else {
            this.trains = trains.filter((train) => !train.isCompleted);
            this.trainsCompleted = trains.filter((train) => train.isCompleted);
          }
          this.checkForSherpaAlert$.next();
        });

        if (this.role === Role.Admin) {
          this.configurationRepository.getSmsBalance().subscribe((balance) => {
            this.smsBalance = balance;
          });
          this.trains = this.trains.filter((_, index) => index < 1);
          if (this.canDeactivateSendingEmail) {
            this.configurationRepository
              .getPreprodEmailSendingConfig()
              .subscribe(({ isSendingEmailActivated }) => {
                this.isSendingEmailActivated = isSendingEmailActivated === 1;
              });
          }
        }

        if (this.role === Role.Sherpa) {
          this.trainRepository
            .getSherpaAvailableTrains()
            .subscribe((trains) => {
              this.availableTrains = trains;
              this.loading = false;
              this.checkForSherpaAlert$.next();
            });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  editPlatformConfiguration() {
    this.dialog
      .open(EditConstantsComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((configuration) => {
        if (configuration) {
          this.configurationRepository
            .updateConstantsConfiguration(configuration)
            .subscribe({
              next: () => {
                this.snackbarService.pushMessage(
                  "Configuration modifiée avec succès",
                  "success"
                );
              },
            });
        }
      });
  }

  editWeeklyTimeSlots() {
    this.dialog
      .open(EditTimeslotsComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((timeSlotsConfiguration) => {
        if (timeSlotsConfiguration) {
          this.configurationRepository
            .updateWeeklyTimeSlots(timeSlotsConfiguration)
            .subscribe({
              next: () => {
                this.snackbarService.pushMessage(
                  "Créneaux modifiés avec succès",
                  "success"
                );
              },
            });
        }
      });
  }

  protected readonly capitalize = capitalize;

  goToMySpace() {
    this.router.navigate(["my-space"]);
  }

  onSwitchChange(event: boolean) {
    const isChecked = event;
    this.configurationRepository
      .updatePreprodEmailSendingConfig({
        isSendingEmailActivated: isChecked ? 1 : 0,
      })
      .subscribe(() => {
        this.isSendingEmailActivated = isChecked;
      });
  }

  restTestData() {
    this.resetDataLoading = true;
    this.configurationRepository.resetTestData().subscribe(() => {
      this.resetDataLoading = false;
      this.snackbarService.pushMessage(
        "Données de test réinitialisées avec succès",
        "success"
      );
      this.storageService.logout();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
  }

  goToOrientation(){
    this.router.navigate(["home", "orientation"]);
  }

  openOnboardingDialog(type: string){
    const dialogData: OnBoardingData = this.getDialogContent(type);

    this.dialog.open(OnBoardingDialogComponent, dialogData ).pipe(takeUntil(this.destroy$)).subscribe(
      () => {
      // console.log('Here');
    });
  }

  getVideoLink() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/3BteSKlP92w`);
  }

  private getDialogContent(type: string): OnBoardingData {
    switch(type) {
      case 'progress':
        return {
          redirectButtonLabel: 'Voir les thématiques de Coaching',
          redirectPath: ['/thematics'],
          queryParams: { type: 'coaching' },
          content: this.sanitizer.bypassSecurityTrustHtml(
            `
            <p>We talk vous accompagne grâce aux <span class="text-bold" style="color: #704c8d">parcours de Coaching en groupe</span>.</p>
            <p class="margin-top">
              Cette dynamique permet, en toute confidentialité, d’améliorer vos compétences et qualités tout en étant plus en alignement avec vous-même. 
              Au-delà de vous libérer, vous êtes challengé.e et transformez cette période en opportunité pour identifier et atteindre des objectifs.
            </p>
            <p class="margin-top">
              L'accompagnant est un coach qui vous guide, vous donne un cadre et vous pose des questions pour vous aider à progresser. 
              Le groupe vous permet d’être dans une démarche active et délibérée de progrès, de booster votre énergie et d’ancrer votre volonté d’atteindre vos objectifs.
            </p>
            `
          ) };
      case 'trial':
        return {
          redirectButtonLabel: 'Voir les thématiques de Parole',
          redirectPath: ['/thematics'],
          queryParams: { type: 'speech' },
          content: this.sanitizer.bypassSecurityTrustHtml(
          `
            <p>We talk vous accompagne grâce aux <span class="text-bold" style="color: #ff8b05">parcours de Parole en groupe</span>.</p>
            <p class="margin-top">
              Cette dynamique permet, en toute confidentialité, d’évacuer votre trop plein d'émotions, de vous soulager, et retrouver du souffle en vous libérant par la parole.
            </p>
            <p class="margin-top">
              L'accompagnant est un thérapeuthe qui est dans une posture d’écoute, de bienveillance et d’empathie. Le groupe vous permet d’être soutenu.e et écouté.e, de vous sentir moins seul.e en voyant que d’autres vivent des situations similaires.
            </p>
          `) };
      case 'lost':
        return {
          redirectButtonLabel: 'Être rappelé.e',
          redirectPath: ['home', 'orientation'],
          content: this.sanitizer.bypassSecurityTrustHtml(
            `
              <p>“Je ne suis pas sûr.e de la thématique à choisir par rapport à ma situation...”</p>
              <p>“Est-ce qu'il est possible de participer à plusieurs thématiques : pro et perso ?”</p>
              <p>“Je me pose beaucoup de questions, j'ai plusieurs sujets à aborder. Quel parcours est le plus adapté ?”</p>
              <p class="margin-top">
                We talk vous propose un <span class="text-bold">appel d’orientation</span> de 10 à 15 minutes avec un coach pour vous aider à choisir votre thématique et votre dynamique d’accompagnement.
              </p>
            `),
        };
      case 'explore':
        return {
          redirectButtonLabel: 'Voir les ressources',
          redirectPath: ['resources', 'all'],
          content: this.sanitizer.bypassSecurityTrustHtml(
            `
              <p>We talk vous propose une variété de <span class="text-bold" style="color: #45818E">Ressources</span> pour explorer et expérimenter en toute autonomie .</p>
              <p class="margin-top">
                Les ressources “Inspiration et réflexion” regroupent des conférences de personnes inspirantes pour vous initier au développement personnel et à ses bénéfices, et vous faire réfléchir sur divers sujets.
              </p>
              <p class="margin-top">
                Les ressources “Expérimentation et bien-être” vous donnent de premières billes et vous permettent de mettre en place des conseils, astuces, et exercices au quotidien pour progresser (“La communication non-violente”, “Savoir dire non”, “Apprendre à faire des compliments”, …).
              </p>
              <p class="margin-top">
                Les ressources “Se faire accompagner” vous présentent les thématiques de la plateforme sous différents angles.
              </p>
            `
          )
        };
      default:
        return {
          redirectButtonLabel: '',
          redirectPath: [],
          content: '',
        };
    }
  }
  
  protected readonly Role = Role;
}
