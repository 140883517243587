import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { YOUTUBE_PLAYER_CONFIG, YouTubePlayer } from "@angular/youtube-player";

import { OrientationResolver } from "../home/resolvers/orientation.resolver";
import { FunnelComponent } from "../shared/components/funnel/funnel.component";
import { SharedModule } from "../shared/shared.module";

import { AdminContentsListComponent } from "./admin-contents-list/admin-contents-list.component";
import { AdminCreateContentComponent } from "./admin-create-content/admin-create-content.component";
import { ContentDetailsComponent } from "./content-details/content-details.component";
import { ContentThematicCardComponent } from "./content-details/content-thematic-card/content-thematic.component";
import { ContentsContainerComponent } from "./contents-container/contents-container.component";
import { ContentsCardPaginationComponent } from "./contents-list/contents-card-pagination/contents-card-pagination.component";
import { ContentsListComponent } from "./contents-list/contents-list.component";
import { ContentReactionsRepository } from "./repositories/content-reactions.repository";
import { ContentsRepository } from "./repositories/contents.repository";
import { ContentCreationResolver } from "./resolvers/content-creation.resolver";
import { ContentListResolver } from "./resolvers/content-list.resolver";
import { ContentResolver } from "./resolvers/content.resolver";
import { ContentsMtmResolver } from "./resolvers/contents-mtm.resolver";
import { TabStoreService } from "./services/content-tab.store";


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        YouTubePlayer,
        RouterModule.forChild([
          {
                path: "all",
                pathMatch: "prefix",
                resolve: {
                  mtm: ContentsMtmResolver,
                },
                children: [
                  {
                    path: "",
                    pathMatch: "full",
                    component: ContentsListComponent,
                    resolve: {
                      profile: OrientationResolver,
                      content: ContentListResolver,
                    }
                  },
                  {
                    path: ":id",
                    pathMatch: "prefix",
                    component: FunnelComponent,
                    resolve: {
                      content: ContentCreationResolver,
                    },
                    children: [
                      {
                        path: "",
                        pathMatch: "full",
                        component: ContentDetailsComponent,
                        resolve: {
                          profile: OrientationResolver
                        }
                      },
                    ]
                  }
                ]
          },
          {
            path: "admin-resources",
            pathMatch: "prefix",
            resolve: {
              mtm: ContentsMtmResolver,
              content: ContentListResolver,
            },
            children: [
              {
                path: "",
                pathMatch: "full",
                component: AdminContentsListComponent, 
              },
              {
                path: ":id",
                pathMatch: "prefix",
                component: FunnelComponent,
                children: [
                  {
                    path: "",
                    pathMatch: "prefix",
                    resolve: {
                      content: ContentCreationResolver,
                    },
                    children: [
    
                      {
                        path: 'new-content',
                        pathMatch: 'full',
                        component: AdminCreateContentComponent,
                        data: {
                          permissions: {
                            only: 'admin',
                            redirectTo: '/'
                          }
                        }
                      },
                      {
                        path: 'edit',
                        pathMatch: 'full',
                        component: AdminCreateContentComponent,
                        data: {
                          permissions: {
                            only: 'admin',
                            redirectTo: '/'
                          },
                          isEdition: true
                        },
                      }
                    ],
                  },
                ],
              },
            ]
          },
      ])
    ],
    declarations: [
        ContentsContainerComponent,
        ContentsListComponent,
        ContentDetailsComponent,
        ContentThematicCardComponent,
        AdminContentsListComponent,
        AdminCreateContentComponent,
        ContentsCardPaginationComponent,
    ],
    providers: [
        ContentsRepository,
        ContentResolver,
        ContentReactionsRepository,
        ContentListResolver,
        ContentCreationResolver,
        ContentsMtmResolver,
        TabStoreService,
        {
          provide: YOUTUBE_PLAYER_CONFIG,
          useValue: {
            loadApi: true,
            disablePlaceholder: true
          }
        }
    ]
})

export class ContentsModule { }