import { addDelayToDate } from "../../shared/tool-functions/date-tools";

import { DateInterval } from "./bar-chart-tools";

export const DEFAULT_FONT = "Roboto, sans-serif";

export const DEFAULT_COLORS = [ '#8364ab', '#FF8B05', '#E37055', '#2D7DC8', '#81C64B', "#E8D414" ];

export enum DateIntervalFilterOption {
  THREE_YEARS = 'three-years',
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month'
}

export function getGlobalDateIntervalByOption(interval: DateIntervalFilterOption): DateInterval {
  switch (interval) {
    case DateIntervalFilterOption.THREE_YEARS:
      return {
        fromDate: addDelayToDate('-3y'),
        toDate: new Date()
      };
    case DateIntervalFilterOption.YEAR:
      return {
        fromDate: addDelayToDate('-1y'),
        toDate: new Date()
      };
    case DateIntervalFilterOption.QUARTER:
      return {
        fromDate: addDelayToDate('-93d'),
        toDate: new Date()
      };
    case DateIntervalFilterOption.MONTH:
      return {
        fromDate: addDelayToDate('-31d'),
        toDate: new Date()
      };
    default:
      return {
        fromDate: new Date(),
        toDate: new Date()
      };
  }
}
