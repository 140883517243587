<div class="header row gap">
  <h1 class="title">Création d'une nouvelle organisation</h1>
  <app-icon button type="close"
            (click)="close()"></app-icon>
</div>

<section>
  <form [formGroup]="newOrganizationForm" (submit)="validate(true)">
    <app-input type="organization"
               formControlName="name"
               title="Nom de l'organisation"
               [error]="nameTaken"
               error-message="Nom déjà utilisé"
               placeholder="ex: Organisation A"></app-input>
    <app-radio-buttons *ngIf="isAdmin" formControlName="gotFormula" title="Ajouter une formule ?"></app-radio-buttons>
    <app-input *ngIf="gotFormula && isAdmin" [dropdownData]="existingFormulaNames"
               formControlName="subscriptionPlan"></app-input>
    <app-radio-buttons formControlName="gotPilot" title="Ajouter un pilote ?"></app-radio-buttons>
    <app-input *ngIf="gotPilot" [addOptionEnable]="true" (addOption)="addPilot()" [dropdownData]="existingTalkerNames"
               formControlName="pilot" type="user"></app-input>
    <div *ngIf="!parent">
      <app-radio-buttons formControlName="gotParent" title="Filiale d'une autre organisation ? "></app-radio-buttons>
      <app-input *ngIf="gotParent"
                 formControlName="parent" type="organization"
                 placeholder="ex: Parent"
                 [dropdownData]="existingNames"
      ></app-input>
      <app-radio-buttons formControlName="gotChildren"
                         title="Organisation mère d'autres organisations ? "></app-radio-buttons>
      <app-checkmarks *ngIf="gotChildren" [checkboxes]="childrenCheckboxes" [isFemaleWord]="true"
                      item-name="Organisation sélectionnée"
                      (changeSelection)="updateChildrenSelection($event)"></app-checkmarks>
    </div>

    <app-radio-buttons formControlName="gotUnits" title="Ajouter des unités ?"></app-radio-buttons>
    <div *ngIf="gotUnits">
      <app-input formControlName="units" type="number" title="Unités allouées" placeholder="0"></app-input>
      <app-input title="Transférer depuis une autre organisation" formControlName="addUnitsFrom" type="organization"
                 [dropdownData]="existingNamesWithUnits"></app-input>
    </div>
    <app-radio-buttons formControlName="gotQuota"
                       title="Ajouter un quota de parcours par collaborateur ?"></app-radio-buttons>
    <app-input *ngIf="gotQuota" formControlName="talkerQuota" type="number" title="Quota par talker"
               placeholder="illimité"></app-input>

    <app-radio-buttons formControlName="gotForbiddenThematics"
                       title="Autoriser à ses collaborateurs seulement certaines thématiques ?"></app-radio-buttons>
    <app-checkmarks *ngIf="gotForbiddenThematics"
                    [checkboxes]="allowedThematicsCheckboxes" [isFemaleWord]="true"
                    (changeSelection)="updateAllowedThematicsSelection($event)"
                    item-name="Thématique autorisée"></app-checkmarks>


    <app-radio-buttons formControlName="gotForbiddenOrganizations"
                       title="Interdire à ses collaborateurs de rencontrer des collaborateurs de certaines organisations lors des parcours ?"></app-radio-buttons>
    <div *ngIf="gotForbiddenOrganizations">
      <app-checkmarks [checkboxes]="forbiddenOrganizationsCheckboxes" [isFemaleWord]="true"
                      item-name="Organisation interdite"
                      (changeSelection)="updateForbiddenOrganizationsSelection($event)"></app-checkmarks>
      <app-checkbox formControlName="intraOrganizationMatching">Autoriser à ses collaborateurs entre eux ?
      </app-checkbox>
    </div>

    <div class="add-button relative">
      <app-button class="center-by-relative-parent" [disabled]="!formIsValid || nameTaken || loading" type="submit">Ajouter cette organisation
      </app-button>
    </div>
  </form>
</section>
