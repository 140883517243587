import { Component, Input, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartData, ChartType } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { combineLatest, Observable, of, startWith, Subject, takeUntil } from "rxjs";

import { DateInterval } from "../utils/bar-chart-tools";
import { DEFAULT_COLORS } from "../utils/utils";

@Component({ template: '' })
export abstract class AbstractChartComponent<Type extends ChartType> {
  @Input() reload$!: Subject<void>;

  @Input() interval!: DateInterval;

  @ViewChild(BaseChartDirective) chart!: BaseChartDirective;

  destroy$: Subject<void> = new Subject<void>();

  chartData: ChartData<Type> = {
    datasets: [],
    labels: []
  };

  abstract type: Type;

  abstract chartOptions: ChartConfiguration<Type>['options'];

  abstract updateData(): void;

  onInit(customObservables$: Observable<any>[] = []): void {
    combineLatest([this.reload$, ...customObservables$])
      .pipe(
        takeUntil(this.destroy$),
        startWith(() => of(null))
      )
      .subscribe({
        next: () => {
          this.updateData();
          this.chart?.update();
        }
      });
  }

  onDestroy(): void {
    this.destroy$.next();
  }

  onChanges(): void {
    this.updateData();
    this.chart?.update();
  }

  getDefaultColors(): string[] {
    return DEFAULT_COLORS;
  }

}
