import { Role } from "../../users/models/users.entity";
import { RoleColors } from "../types/role-colors";

import { capitalize } from "./capitalize";

export function getColorByRole(role: Role): RoleColors {
    return RoleColors[role];
}

export function getUserRole(userProfile: any): string {
  return userProfile.role === Role.Talker && userProfile.organizationAssociations.length > 1 
  ? 'Pilote' 
  : capitalize(userProfile.role);
}