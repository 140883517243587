export enum FormatEnum {
    VIDEO = 'video',
    PODCAST = 'podcast',
    PRACTICAL_SHEET = 'pratical_sheet',
    TEST = 'test',
}

export enum PillarEnum {
    INDIVIDUAL = 'individual',
    ENVIRONMENT = 'environment',
    EVENT = 'event',
}

export enum ObjectiveEnum {
    INSPIRE = 'inspire',
    TRANSFORM = 'transform',
    ACT = 'act',
    PREPARE = 'prepare'
}

export function getFormatNameBySlug(slug: string): string {
    let format;
    switch (slug) {
        case FormatEnum.VIDEO:
            format = 'Vidéo';
            break;
        case FormatEnum.PODCAST:
            format = 'Podcast';
            break;
        case FormatEnum.PRACTICAL_SHEET:
            format = 'Fiche pratique';
            break;
        default:
            return '';
    }
    return format;
}

export function getObjectiveNameBySlug(slug: string): string {
    let objective;
    switch (slug) {
        case ObjectiveEnum.INSPIRE:
            objective = 'Inspiration & réflexion';
            break;
        case ObjectiveEnum.TRANSFORM:
            objective = 'Expérimentation & bien être';
            break;
        case ObjectiveEnum.ACT:
            objective = 'Se faire accompagner';
            break;
        case ObjectiveEnum.PREPARE:
            objective = 'Préparer une séance';
            break;
        default:
            return '';
    }
    return objective;
}

export function getPillarNameBySlug(slug: string): string {
    let pillar;
    switch (slug) {
        case PillarEnum.INDIVIDUAL:
            pillar = 'individual';
            break;
        case PillarEnum.ENVIRONMENT:
            pillar = 'environment';
            break;
        case PillarEnum.EVENT:
            pillar = 'event';
            break;
        default:
            return '';
    }
    return pillar;
}