import { Component, HostListener, Input } from "@angular/core";
import { ChartOptions } from "chart.js";
import { Observable, Subject, Subscription } from "rxjs";

import { AbstractChartComponent } from "../abstract.chart.component";

const defaultLabels = ['Quotidien, activités', "Sentiment d'utilité, sens de la vie", 'Estime de soi, confiance en soi', 'Humeur, énergie mentale', 'Interactions, relations', 'Reconnaissance professionnelle, capacités', 'Motivation au travail, intérêt', 'Attention et concentration au travail', 'Santé, énergie physique', 'Impact de la société'];

@Component({
    selector: 'app-strengths-weakness-chart',
    templateUrl: './strengths-weakness-chart.component.html',
})

export class StrengthWeaknessChartComponent extends AbstractChartComponent<'radar'>  {
  
  type: 'radar' = 'radar';

  resize$ = new Subject<void>();

  width!: number;
  
  height!: number;
  
  @Input() data$: any;

  data: any;

  private dataSubscription!: Subscription;
    
  chartOptions: ChartOptions<'radar'> = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false 
          }
        },
        scales: {
          r: {
            min: 0, 
            max: 10,
            ticks: {
              stepSize: 2
            },
            pointLabels: {
              font: {
                size: window.innerWidth < 500 ? 10 : 13
              },
              callback: (value: string, index: number) => {
                const totalLabels = defaultLabels.length;
                
                const angle = (index / totalLabels) * 360;
                
                const isOnSide = (angle > 60 && angle < 120) || (angle > 240 && angle < 300);
                
                if (isOnSide && value.length > 15) {
                  const words = value.split(' ');
                  const lines = [];
                  let currentLine = '';
                  
                  words.forEach(word => {
                    if (currentLine.length + word.length <= 15) {
                      currentLine += (currentLine ? ' ' : '') + word;
                    } else {
                      lines.push(currentLine);
                      currentLine = word;
                    }
                  });
                  
                  if (currentLine) {
                    lines.push(currentLine);
                  }
                  
                  return lines;
                }
                
                return value;
              }
            }
          }
        }
      };
    
      ngOnInit(): void {
        this.onInit([
            this.resize$,
            ...(this.data$ && this.data$ instanceof Observable ? [this.data$] : []),
          ]);

        this.dataSubscription = this.data$.subscribe((data: any) => {
          this.data = data;
          this.updateData();
          this.chart?.update();
        });

        this.getWidth();
        this.getHeight();
      }

      ngAfterViewInit(): void {
        if (this.chart) {
            this.chart.update();
          }
      }

      ngOnDestroy(): void {
      
      }

      updateData() {
        const labels = this.data ? Object.values(this.data).map((metric: any) => metric.label) : defaultLabels;
        const values = Object.values(this.data || []).map((metric: any) => metric?.value) ?? [];
        this.chartData = {
          labels,
          datasets: [
            {
              data: values,
              label: undefined,
              borderColor: '#8C5FB0',           
              backgroundColor: 'rgba(140, 95, 176, 0.2)', 
              pointBackgroundColor: '#8C5FB0',  
              pointBorderColor: '#fff',        
              pointHoverBackgroundColor: '#fff', 
              pointHoverBorderColor: '#8C5FB0' 
            }
          ]
        };
      }

      @HostListener('window:resize')
      getWidth(): void {
        const BREAKPOINT = 1000;
        const CONTAINER_PADDING = 32;
        
        if (window.innerWidth < BREAKPOINT) {
          const containerWidth = window.innerWidth - CONTAINER_PADDING;
          this.width = Math.floor(containerWidth * 0.9);
        } else {
          const containerWidth = window.innerWidth - CONTAINER_PADDING;
          this.width = Math.floor(containerWidth * 0.5); 
        }
        
        this.resize$.next();
      }
        
      @HostListener('window:resize')
      getHeight(): void {
        const BREAKPOINT = 1000;
        const CONTAINER_PADDING = 32;
        
        if (window.innerWidth < BREAKPOINT) {
          const containerHeight = window.innerHeight - CONTAINER_PADDING;
          this.height = Math.floor(containerHeight * 0.9);
        } else {
          const containerHeight = window.innerHeight - CONTAINER_PADDING;
          this.height = Math.floor(containerHeight * 0.5);
        }
        
        this.resize$.next();
      }
}